import React, { useState, useRef } from "react";
import { Link, NavLink } from "react-router-dom";
import { useClickAway } from "react-use";
import { Squash as Hamburger } from "hamburger-react";
import { AnimatePresence, motion } from "framer-motion";
import "../font/Sentic Text.otf"
export const Navbar = () => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  useClickAway(ref, () => setOpen(false));
  // const [navstate, setnavstate] = useState(false);
  // const dropdownmenu = () => {
  //   console.log("clicked");
  //   setnavstate(!navstate);
  // };

  const navlist = [
    {
      menu: "Home",
      link: "",
    },
    {
      menu: "Programs",
      link: "",
    },
    {
      menu: "Resources",
      link: "",
    },
    {
      menu: "ForBusiness",
      link: "",
    },
    {
      menu: "ForColleges",
      link: "",
    },
    {
      menu: "Contact Us",
      link: "",
    },
  ];
  return (
    <div>
      <nav className="min-[320px]:w-full h-24 sm:h-28 md:h-32 lg:h-40 4xl:h-48 5xl:h-64 6xl:h-80 7xl:h-96 flex items-center justify-between px-5 xl:px-20 lg:px-10 7xl:px-28 bg-[#0B192C] sm:w-full font-[light]">
        <div className=" flex  items-center lg:gap-12 xl:gap-16 5xl:gap-32 6xl:gap-64 7xl:gap-96">
          <div className=" w-[20%] flex items-center text-center mt-4 gap-2 sm:gap-2 md:gap-1 lg:gap-1 xl:gap-0">
          <img
            src="/assets/image 139.png"
            alt=""
            className=" w-[60%] sm:w-[40%] md:w-[39%] lg:w-[37%]  xl:w-[35%]  4xl:w-[40%] 5xl:w-[50%] 6xl:w-[52%] "
          />
          <h1 className=" -ml-3 text-white sm:text-xl text-lg md:text-2xl lg:text-3xl xl:text-4xl  4xl:text-5xl 5xl:text-7xl 6xl:text-8xl 7xl:text-9xl 5xl:-ml-7 7xl:-ml-14  font-sans font-bold">MentorFox</h1>
          </div>
          <ul className="lg:flex gap-10 4xl:gap-16 5xl:gap-24 6xl:gap-28 7xl:gap-36 text-lg xl:text-xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl  text-white font-sans font-extralight pt-6 hidden ">
            <li>Courses</li>
            <li>Programs</li>
            <li>Resources</li>
            <li>For Business</li>
            <li>For Colleges</li>
          </ul>
        </div>
        <div className=" pt-6 hidden lg:flex">
          <button className=" flex items-center justify-center w-28 h-10 4xl:w-44 4xl:h-16 4xl:text-2xl 5xl:w-64 5xl:h-24 5xl:text-4xl 6xl:w-[360px] 6xl:h-32 6xl:text-5xl 7xl:w-[420px] 7xl:h-40 7xl:text-5xl border border-white rounded-full text-white ">
            Contact Us
          </button>
        </div>
        <div className=" lg:hidden  ">
          <Hamburger
            toggled={isOpen}
            size={25}
            toggle={setOpen}
            color="white"
          />
          <AnimatePresence>
            {isOpen && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                className=" w-screen fixed left-0 shadow-4xl right-0 top-[8rem] p-5 pt-0 bg-[#0B192C] border-b border-b-white/20"
              >
                
                <ul className="grid gap-2 text-white">
                  {navlist.map((items, idx) => {
                    return (
                      <motion.li
                        initial={{ scale: 0, opacity: 0 }}
                        animate={{ scale: 1, opacity: 1 }}
                        transition={{
                          type: "spring",
                          stiffness: 260,
                          damping: 20,
                          delay: 0.1 + idx / 10,
                        }}
                        key={items.menu}
                        className="w-full p-[0.08rem] rounded-xl bg-[#202E41] "
                      >
                        <a
                          onClick={() => setOpen((prev) => !prev)}
                          className={
                            "flex items-center justify-between w-full p-5 rounded-xl bg-[#0B192C] text-white"
                          }
                        >
                          {items.menu}
                        </a>
                      </motion.li>
                    );
                  })}
                </ul>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </nav>
    </div>
  );
};
