import React from "react";
import { useState, useRef, useEffect } from "react";
import { IoIosArrowForward } from "react-icons/io";// import "./job.css";
export const Jobguarantee = () => {
  const [active, setActive] = useState(false);
  const [active1, setActive1] = useState(false);
  const contentRef = useRef(null);
  const contentRef1 = useRef(null);
  useEffect(() => {
    contentRef.current.style.maxHeight = active
      ? `${contentRef.current.scrollHeight}px`
      : "0px";
      contentRef1.current.style.maxHeight = active1
      ? `${contentRef1.current.scrollHeight}px`
      : "0px";
  }, [contentRef,contentRef1, active,active1]);
  

  const toggleAccordion = () => {
    setActive(!active);
  
  };
  const toggleAccordion1 = () => {
    setActive1(!active1);
  
  };
  return (
    <div className=" w-full bg-[#0B192C] pb-20 4xl:pb-36 5xl:pb-44 6xl:pb-52 7xl:pb-56">
    <div className=" w-full bg-[#202E41] py-20 4xl:py-36 5xl:py-44 6xl:py-52 7xl:py-64 text-white flex flex-col items-center gap-11 sm:gap-16 4xl:gap-24 5xl:gap-32 6xl:gap-44 7xl:gap-48">
      <h1 className="text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 4xl:text-6xl 5xl:text-7xl 6xl:text-8xl 7xl:text-9xl font-black">
        MentorFox's Job Guarentee Explained
      </h1>
      <p className="text-lg md:text-xl lg:text-2xl text-center xl:text-3xl 4xl:text-4xl 6xl:text-6xl 7xl:text-7xl 7xl:leading-normal 6xl:leading-normal 4xl:leading-normal 5xl:text-5xl 5xl:leading-normal font-medium w-[84%] ">
        MentorFox is a partner in our learner's career tranformation and belive
        that we deserve a portion of the fee only after a successfull outcome
        for our learners.Thus, our Job Guarantee Program have a component of
        "Success Fee", which waived off if the job guarantee is not met within 6
        months placement eligibility date.
      </p>
      <div className=" text-center  flex flex-col gap-8 4xl:gap-10 5xl:gap-12 6xl:gap-14 7xl:gap-16">
        <div>
          <button
            className={`bg-[#D9D9D9] w-[70%] rounded-[35px] 5xl:rounded-[45px] 6xl:rounded-[55px] 7xl:rounded-[65px] p-3 shadow-lg shadow-gray-800 cursor-pointer ${active}`}
            onClick={toggleAccordion}
          >
            <div>
            <div className="flex justify-between text-left min-h-2 items-center">
                <div className=" flex items-center 4xl:gap-3 5xl:gap-5">
                <div className="h-10 w-10 md:w-14 md:h-14 2xl:h-20 2xl:w-20 4xl:h-24 4xl:w-24 5xl:h-32 5xl:w-32 6xl:h-44 6xl:w-44 7xl:h-52 7xl:w-52 rounded-full bg-[#486997]"> </div>
                <h4 className="ml-2 text-xl xs:text-2xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl text-black font-medium">Who is Eligible?</h4>
                </div>
                <IoIosArrowForward
                  className={active ? `text-black cursor-pointer text-2xl 4xl:text-4xl 5xl:text-5xl 6xl:text-7xl 7xl:text-8xl 5xl:mr-4 6xl:mr-6 7xl:mr-8  mr-2 transform rotate-90` : `text-black cursor-pointer text-2xl 4xl:text-4xl 5xl:text-5xl 6xl:text-7xl 7xl:text-8xl 5xl:mr-4 6xl:mr-6 7xl:mr-8 mr-2  `}
                />
              </div>
              <div
                ref={contentRef}
                className={active ? `mx-2 overflow-hidden transition ease-in  duration-1000 text-left text-black` : `mx-2 overflow-hidden transition ease-in  duration-1000 text-left text-black`}
              >
                <p className=" text-xl py-3 4xl:text-3xl 5xl:text-4xl 5xl:leading-normal 6xl:text-5xl 6xl:leading-normal 7xl:text-7xl 7xl:leading-normal">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Aliquid quos repellendus quidem perspiciatis, minima accusamus
                  eum ea fugiat a. Incidunt accusamus sapiente veritatis officia
                  obcaecati repellendus voluptate accusantium perspiciatis
                  quasi!
                </p>
              </div>
            </div>
          </button>
        </div>
        <div>
          <button
            className={` bg-[#D9D9D9] w-[70%] rounded-[35px] 5xl:rounded-[45px] 6xl:rounded-[55px] 7xl:rounded-[65px] p-3 shadow-lg shadow-gray-800 cursor-pointer ${active1}`}
            onClick={toggleAccordion1}
          >
            <div>
              <div className="flex justify-between text-left min-h-2 items-center">
                <div className=" flex items-center 4xl:gap-3 5xl:gap-5">
                <div className=" h-10 w-10 md:w-14 md:h-14 2xl:h-20 2xl:w-20 4xl:h-24 4xl:w-24 5xl:h-32 5xl:w-32 6xl:h-44 6xl:w-44 7xl:h-52 7xl:w-52 rounded-full bg-[#486997]"> </div>
                <h4 className="ml-2 text-xl xs:text-2xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl text-black font-medium">How does it work?</h4>
                </div>
                <IoIosArrowForward
                  className={active1 ? `text-black cursor-pointer text-2xl 4xl:text-4xl 5xl:text-5xl 6xl:text-7xl 7xl:text-8xl 5xl:mr-4 6xl:mr-6 7xl:mr-8 mr-2 transform rotate-90` : `text-black cursor-pointer 4xl:text-4xl 5xl:text-5xl 6xl:text-7xl 7xl:text-8xl text-2xl 5xl:mr-4 6xl:mr-6 7xl:mr-8 mr-2 `}
                />
              </div>
              <div
                ref={contentRef1}
                className={active1 ? `mx-2 overflow-hidden transition ease-in  duration-1000 text-left text-black` : `mx-2 overflow-hidden transition ease-in  duration-1000 text-left text-black`}
              >
                <p className=" text-xl py-3 4xl:text-3xl 5xl:text-4xl 5xl:leading-normal 6xl:text-5xl 6xl:leading-normal 7xl:text-7xl 7xl:leading-normal">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  Aliquid quos repellendus quidem perspiciatis, minima accusamus
                  eum ea fugiat a. Incidunt accusamus sapiente veritatis officia
                  obcaecati repellendus voluptate accusantium perspiciatis
                  quasi!
                </p>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
    </div>
  );
};
