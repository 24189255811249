import React from 'react'
import { PiSuitcaseSimpleBold } from "react-icons/pi";
import { FaInfinity } from "react-icons/fa6";
import { RiGraduationCapLine } from "react-icons/ri";
import { MdOutlineCloud } from "react-icons/md";

export const Whoistfor = () => {
 
  return (
    <div className=' w-full bg-[#0B192C] py-20 4xl:py-32 5xl:py-44 6xl:py-56 7xl:py-72 flex flex-col items-center'>
        <div className=' w-[84%] flex flex-col gap-12 4xl:gap-20 5xl:gap-28 6xl:gap-36 7xl:gap-44'>
        <h1 className=' text-3xl md:text-5xl font-black text-white 4xl:text-6xl 5xl:text-7xl 6xl:text-8xl 7xl:text-9xl '>Who is it for </h1>
        <div  className={` w-full grid grid-cols-1 md:grid-cols-2 gap-20 sm:gap-20 md:gap-16 lg:gap-24 4xl:gap-56 5xl:gap-72 6xl:gap-80 7xl:gap-56 `}>
            
                <div className=' border-2  border-[#FFB039]  h-72 lg:h-[255px] xl:h-72 3xl:h-80 4xl:h-[420px] 5xl:h-[450px] 6xl:h-[540px] 7xl:h-[680px] 4xl:p-12 rounded-tl-3xl rounded-br-3xl 7xl:rounded-tl-[80px] 7xl:rounded-br-[80px] 7xl:py-24 7xl:pl-24 flex p-5 gap-8 '>
                    <div className=' w-[20%] h-20 4xl:h-28 5xl:h-36 6xl:h-48 7xl:h-60 rounded-3xl bg-[#213147] flex justify-center items-center'>
                    <PiSuitcaseSimpleBold  className='text-4xl lg:text-6xl 4xl:text-7xl 5xl:text-8xl 6xl:text-9xl 7xl:text-[140px] text-white'/>
                         </div>
                         <div className='flex flex-col text-white  w-[80%] gap-4 md:gap-3 lg:gap-6 md:pt-2 lg:pt-4 4xl:gap-16 4xl:leading-normal 5xl:gap-20 5xl:leading-normal'>
                            <h1 className=' text-2xl sm:text-3xl md:text-2xl lg:text-4xl 3xl:text-5xl 4xl:text-6xl 5xl:text-7xl 6xl:text-8xl 7xl:text-9xl font-medium '>IT Professionals</h1>
                            <p className='text-xl sm:text-2xl  md:text-lg lg:text-xl xl:text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl 4xl:leading-normal 5xl:leading-normal 6xl:leading-normal 7xl:leading-normal '>Update your knowledge, stay current with industry trends, and enhance your expertise in cloud and DevOps technolgies.</p>
                         </div>
                </div>
                <div className=' border-2  border-[#FF4949]  h-72 lg:h-[255px] xl:h-72 3xl:h-80 4xl:h-[420px] 5xl:h-[450px] 6xl:h-[540px] 7xl:h-[680px] 4xl:p-12 rounded-tl-3xl rounded-br-3xl 7xl:rounded-tl-[80px] 7xl:rounded-br-[80px] 7xl:py-24 7xl:pl-24 flex p-5 gap-8'>
                    <div className=' w-[20%] h-20 4xl:h-28 5xl:h-36 6xl:h-48 7xl:h-60 rounded-3xl bg-[#213147] flex justify-center items-center'>
                    <RiGraduationCapLine  className=' text-4xl  4xl:text-7xl 5xl:text-8xl 6xl:text-9xl 7xl:text-[140px] lg:text-6xl text-white'/>
                         </div>
                         <div className='flex flex-col text-white  w-[80%] gap-4 md:gap-3 lg:gap-6 md:pt-2 lg:pt-0 4xl:gap-16 4xl:leading-normal 5xl:gap-20 5xl:leading-normal'>
                            <h1 className=' text-2xl sm:text-3xl md:text-2xl lg:text-4xl 3xl:text-5xl 4xl:text-6xl 5xl:text-7xl 6xl:text-8xl 7xl:text-9xl font-medium'>College Graduates</h1>
                            <p className=' text-xl sm:text-2xl  md:text-lg lg:text-xl xl:text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl 4xl:leading-normal 5xl:leading-normal 6xl:leading-normal 7xl:leading-normal '>Start your Career essential IT skills in Cloud Computing and DevOps to stand out in the job market.</p>
                         </div>
                </div>
            
                <div className=' border-2  border-[#00CA91]  h-72 lg:h-[255px] xl:h-72 3xl:h-80 4xl:h-[420px] 5xl:h-[450px] 6xl:h-[540px] 7xl:h-[680px] 4xl:p-12 rounded-tl-3xl rounded-br-3xl 7xl:rounded-tl-[80px] 7xl:rounded-br-[80px] 7xl:py-24 7xl:pl-24 flex p-5 gap-8'>
                    <div className=' w-[20%] h-20 4xl:h-28 5xl:h-36 6xl:h-48 7xl:h-60 rounded-3xl bg-[#213147] flex justify-center items-center'>
                    <FaInfinity  className=' text-4xl  lg:text-6xl 4xl:text-7xl 5xl:text-8xl 6xl:text-9xl 7xl:text-[140px] text-white'/>
                         </div>
                         <div className='flex flex-col text-white  w-[80%] gap-4 md:gap-3 lg:gap-6 md:pt-2 lg:pt-0 4xl:gap-16 4xl:leading-normal 5xl:gap-20 5xl:leading-normal'>
                            <h1 className=' text-2xl sm:text-3xl md:text-2xl lg:text-4xl font-medium 3xl:text-5xl 4xl:text-6xl 5xl:text-7xl 6xl:text-8xl 7xl:text-9xl'>Aspiring DevOps Engineers</h1>
                            <p className=' text-xl sm:text-2xl  md:text-lg lg:text-xl xl:text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl 4xl:leading-normal 5xl:leading-normal 6xl:leading-normal 7xl:leading-normal'>Individuals who aspire to become DevOps engineers and are looking to upskill in the feild of DevOps.</p>
                         </div>
                </div>
                <div className=' border-2  border-[#CA85EA]  h-72 lg:h-[255px] xl:h-72 3xl:h-80 4xl:h-[420px] 5xl:h-[450px] 6xl:h-[540px] 7xl:h-[680px] 4xl:p-12 rounded-tl-3xl rounded-br-3xl 7xl:rounded-tl-[80px] 7xl:rounded-br-[80px] 7xl:py-24 7xl:pl-24 flex p-5 gap-8'>
                    <div className=' w-[20%] h-20 4xl:h-28 5xl:h-36 6xl:h-48 7xl:h-60 rounded-3xl bg-[#213147] flex justify-center items-center'>
                    <MdOutlineCloud  className=' text-4xl lg:text-6xl 4xl:text-7xl 5xl:text-8xl 6xl:text-9xl 7xl:text-[140px] text-white'/>
                         </div>
                         <div className='flex flex-col text-white  w-[80%] gap-4 md:gap-3 lg:gap-6 md:pt-2 lg:pt-0 4xl:gap-16 4xl:leading-normal 5xl:gap-20 5xl:leading-normal'>
                            <h1 className=' text-2xl sm:text-3xl md:text-2xl lg:text-4xl font-medium 3xl:text-5xl 4xl:text-6xl 5xl:text-7xl 6xl:text-8xl 7xl:text-9xl'>Aspiring Cloud Engineers</h1>
                            <p className=' text-xl sm:text-2xl  md:text-lg lg:text-xl xl:text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl 4xl:leading-normal 5xl:leading-normal 6xl:leading-normal 7xl:leading-normal '>Individuals who aspire to become cloud engineers and are looking to upskill in the feild of DevOps.</p>
                         </div>
                </div>
            
        </div>
        </div>

    </div>
  )
}
