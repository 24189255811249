import React, { useState } from "react";
import { FaPlay } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { IoPersonCircleOutline } from "react-icons/io5";
import { MdOutlinePhoneEnabled } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";
import { IconField } from "primereact/iconfield";
import { IoIosArrowForward } from "react-icons/io";
import { Button } from "primereact/button";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { PiGraduationCapBold } from "react-icons/pi";
import { IoBookOutline } from "react-icons/io5";
import { Dialog } from "primereact/dialog";
import { MdOutlineArrowForward } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import axios from "axios";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
const project = [
  {
    imgurl: "/assets/project1.png",
    projectname: "Edtech company",
    details:
      "Implementing MEAN stack LMS system. Use Tools like kafka,html,css,js.",
    tech1: "Kafka",
    tech2: "MongoDB",
    tech3: "HTML & CSS",
    tech4: "React JS",
  },
  {
    imgurl: "/assets/project1.png",
    projectname: "Edtech company",
    details:
      "Implementing MEAN stack LMS system. Use Tools like kafka,html,css,js.",
    tech1: "Kafka",
    tech2: "MongoDB",
    tech3: "HTML & CSS",
    tech4: "React JS",
  },
  {
    imgurl: "/assets/project2.png",
    projectname: "Edtech company",
    details:
      "Implementing MEAN stack LMS system. Use Tools like kafka,html,css,js.",
    tech1: "Kafka",
    tech2: "MongoDB",
    tech3: "HTML & CSS",
    tech4: "React JS",
  },
];

export const Project = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const initialstate = {
    Email: "",
    Phone: "",
    Name: "",
    Education: "",
    YOP: "",
    NotificationPermission: false,
  };

  const [detail, setdetail] = useState(initialstate);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setdetail({ ...detail, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(detail);

    await axios
      .post("http://localhost:4000/enroll", detail)
      .then((res) => {
        if (res.data) {
          toast.success("Enrolled successfully");
          setVisible(false);
        }
      })
      .catch((err) => console.log("not enrolled"))
      .finally(() => {
        // setShowModal1(false)
      });
  };

  return (
    <div className=" w-full bg-[#00CA91] pt-14 sm:py-14 flex flex-col gap-20 4xl:py-20 4xl:gap-28 5xl:py-28 5xl:gap-32 6xl:py-32 6xl:gap-36 7xl:py-52 7xl:gap-52">
      <h1 className="text-2xl sm:text-3xl 3xl:text-4xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl 7xl:text-8xl font-bold text-center">
        Real time projects in this program
      </h1>
      <div className=" hidden lg:flex w-full  justify-center gap-52 7xl:gap-[520px]">
        <div className=" w-[45%]  lg:w-[35%] rounded-tl-3xl rounded-br-3xl 4xl:rounded-tl-[32px] 4xl:rounded-br-[32px] 5xl:rounded-tl-[40px] 5xl:rounded-br-[40px] 6xl:rounded-tl-[64px] 6xl:rounded-br-[64px] 7xl:rounded-tl-[72px] 7xl:rounded-br-[72px] gap-6 4xl:gap-10 5xl:gap-14 6xl:gap-20 7xl:gap-15  bg-white flex flex-col items-center py-10 4xl:py-20 5xl:py-32 6xl:py-40">
          <img
            src="/assets/project1.png"
            className=" w-[65%] 7xl:w-[55%] xl:h-80 2xl:h-96 4xl:h-[430px]  5xl:h-[590px]  6xl:h-[720px]  7xl:h-[880px]  lg:h-64 md:h-60  rounded-3xl "
          ></img>
          <h1 className="w-[65%] text-2xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl 7xl:text-8xl font-medium text-center">
            Edtech company
          </h1>
          <p className="w-[65%] text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl 7xl:text-6xl 4xl:leading-normal 5xl:leading-normal 6xl:leading-normal 7xl:leading-normal text-center">
            Implementing MEAN stack LMS system. Use Tools like
            kafka,html,css,js.
          </p>
          <div className=" flex flex-col gap-5 4xl:gap-10 5xl:gap-16">
            <div className=" flex gap-5 4xl:gap-10 5xl:gap-16">
              <button className=" w-36 xl:w-40 4xl:w-64 4xl:h-16 4xl:rounded-full 5xl:w-96 5xl:h-24 5xl:rounded-full 6xl:w-[480px] 6xl:h-32 6xl:rounded-full 7xl:w-[550px] 7xl:h-44 7xl:rounded-full 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl  text-lg xs:text-lg sm:text-xl h-10 border-2 border-black rounded-3xl font-medium">
                Kafka
              </button>
              <button className=" w-36 xl:w-40  4xl:w-64 4xl:h-16 4xl:rounded-full 4xl:text-4xl 5xl:w-96 5xl:h-24 5xl:rounded-full 6xl:w-[480px] 6xl:h-32 6xl:rounded-full 7xl:w-[550px] 7xl:h-44 7xl:rounded-full  5xl:text-5xl 6xl:text-6xl 7xl:text-7xl  text-lg xs:text-lg sm:text-xl h-10 border-2 border-black rounded-3xl font-medium">
                MongoDB
              </button>
            </div>
            <div className=" flex gap-5 4xl:gap-10 5xl:gap-16">
              <button className="w-36 xl:w-40 4xl:w-64 4xl:h-16 4xl:rounded-full  5xl:w-96 5xl:h-24 5xl:rounded-full 6xl:w-[480px] 6xl:h-32 6xl:rounded-full 7xl:w-[550px] 7xl:h-44 7xl:rounded-full 4xl:text-4xl  5xl:text-5xl 6xl:text-6xl 7xl:text-7xl  text-lg xs:text-lg sm:text-xl h-10 border-2 border-black rounded-3xl font-medium">
                HTML & CSS
              </button>
              <button className=" w-36 xl:w-40 4xl:w-64 4xl:h-16 4xl:rounded-full  5xl:w-96 5xl:h-24 5xl:rounded-full 6xl:w-[480px] 6xl:h-32 6xl:rounded-full 7xl:w-[550px] 7xl:h-44 7xl:rounded-full 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl  text-lg xs:text-lg sm:text-xl h-10 border-2 border-black rounded-3xl font-medium">
                React JS
              </button>
            </div>
          </div>
        </div>
        <div className=" w-[45%]  lg:w-[35%] rounded-tl-3xl rounded-br-3xl 4xl:rounded-tl-[32px] 4xl:rounded-br-[32px] 5xl:rounded-tl-[40px] 5xl:rounded-br-[40px] 6xl:rounded-tl-[64px] 6xl:rounded-br-[64px] 7xl:rounded-tl-[72px] 7xl:rounded-br-[72px] gap-6 4xl:gap-10 5xl:gap-14 6xl:gap-20 7xl:gap-15  bg-white flex flex-col items-center py-10 4xl:py-20 5xl:py-32 6xl:py-40">
          <img
            src="/assets/project2.png"
            className=" w-[65%] 7xl:w-[55%] xl:h-80 2xl:h-96 4xl:h-[430px]  5xl:h-[590px]  6xl:h-[720px]  7xl:h-[880px]  lg:h-64 md:h-60  rounded-3xl "
          ></img>
          <h1 className="w-[65%] text-2xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl 7xl:text-8xl font-medium text-center">
            Edtech company
          </h1>
          <p className="w-[65%] text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl 7xl:text-6xl 4xl:leading-normal 5xl:leading-normal 6xl:leading-normal 7xl:leading-normal text-center">
            {" "}
            Implementing MEAN stack LMS system. Use Tools like
            kafka,html,css,js.{" "}
          </p>
          <div className=" flex flex-col gap-5 4xl:gap-10 5xl:gap-16">
            <div className=" flex gap-5 4xl:gap-10 5xl:gap-16">
              <button className=" w-36 xl:w-40 4xl:w-64 4xl:h-16 4xl:rounded-full 5xl:w-96 5xl:h-24 5xl:rounded-full 6xl:w-[480px] 6xl:h-32 6xl:rounded-full 7xl:w-[550px] 7xl:h-44 7xl:rounded-full 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl  text-lg xs:text-lg sm:text-xl h-10 border-2 border-black rounded-3xl font-medium">
                Kafka
              </button>
              <button className=" w-36 xl:w-40  4xl:w-64 4xl:h-16 4xl:rounded-full 4xl:text-4xl 5xl:w-96 5xl:h-24 5xl:rounded-full 6xl:w-[480px] 6xl:h-32 6xl:rounded-full 7xl:w-[550px] 7xl:h-44 7xl:rounded-full  5xl:text-5xl 6xl:text-6xl 7xl:text-7xl  text-lg xs:text-lg sm:text-xl h-10 border-2 border-black rounded-3xl font-medium">
                MongoDB
              </button>
            </div>
            <div className=" flex gap-5 4xl:gap-10 5xl:gap-16">
              <button className="w-36 xl:w-40 4xl:w-64 4xl:h-16 4xl:rounded-full  5xl:w-96 5xl:h-24 5xl:rounded-full 6xl:w-[480px] 6xl:h-32 6xl:rounded-full 7xl:w-[550px] 7xl:h-44 7xl:rounded-full 4xl:text-4xl  5xl:text-5xl 6xl:text-6xl 7xl:text-7xl  text-lg xs:text-lg sm:text-xl h-10 border-2 border-black rounded-3xl font-medium">
                HTML & CSS
              </button>
              <button className=" w-36 xl:w-40 4xl:w-64 4xl:h-16 4xl:rounded-full  5xl:w-96 5xl:h-24 5xl:rounded-full 6xl:w-[480px] 6xl:h-32 6xl:rounded-full 7xl:w-[550px] 7xl:h-44 7xl:rounded-full 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl  text-lg xs:text-lg sm:text-xl h-10 border-2 border-black rounded-3xl font-medium">
                React JS
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full justify-center lg:hidden overflow-x-scroll">
        {project.map((items) => (
          <div className=" w-[95%] flex  flex-shrink-0 justify-center">
            <div className=" w-[80%] md:w-[80%] ml-0 xs:ml-5  sm:ml-0 md:ml-0   rounded-tl-3xl rounded-br-3xl gap-6  bg-white flex flex-col items-center py-10  ">
              <img
                src={items.imgurl}
                className=" w-56 xs:w-64 sm:w-80 md:w-96 lg:w-[65%] xl:h-72 lg:h-64 md:h-80 sm:h-64 h-56  rounded-3xl "
              ></img>
              <h1 className="w-[65%] text-2xl font-medium text-center">
                {items.projectname}
              </h1>
              <p className="w-[65%] text-xl text-center">{items.details} </p>
              <div className=" flex flex-col gap-5 ">
                <div className=" flex gap-5">
                  <button className="  w-36 xl:w-40 text-lg xs:text-base sm:text-xl h-10 border-2 border-black rounded-3xl font-medium">
                    {items.tech1}
                  </button>
                  <button className="  w-36 xl:w-40 text-lg xs:text-base sm:text-xl h-10 border-2 border-black rounded-3xl font-medium">
                    {items.tech2}
                  </button>
                </div>
                <div className=" flex gap-5">
                  <button className="  w-36 xl:w-40 text-lg xs:text-base sm:text-xl h-10 border-2 border-black rounded-3xl font-medium">
                    {items.tech3}
                  </button>
                  <button className=" w-36 xl:w-40 text-lg xs:text-base sm:text-xl h-10 border-2 border-black rounded-3xl font-medium">
                    {items.tech4}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className=" w-full flex justify-center 7xl:h-72 xl:h-[68px] 3xl:h-24 4xl:h-36 5xl:h-44 6xl:h-60 ">
        <button
          onClick={() => setVisible(true)}
          className=" 7xl:w-[670px] 7xl:h-64 6xl:w-[480px] 6xl:h-48 5xl:w-96 5xl:h-36 4xl:w-80 4xl:h-28 3xl:w-64 3xl:h-20 2xl:w-52 xl:h-16 xl:w-40 2xl:h-[60px]  lg:w-36 lg:h-14 md:w-36 md:h-14 sm:w-32 sm:h-12 sm:text-base xs:w-28 xs:h-10 xs:text-sm  w-24 h-8 6xl:gap-7 7xl:gap-12  rounded-lg 5xl:rounded-xl 7xl:rounded-3xl items-center justify-center   md:gap-3 sm:gap-3 xs:gap-2 gap-1 md:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl 4xl:text-[37px] 5xl:text-5xl 6xl:text-7xl 7xl:text-8xl text-xs pb-1  5xl:gap-5  hover:bg-[#f79e1a] transition-all duration-500  bg-[#FFB039] flex font-bold  "
        >
          Enroll Now
          <FaPlay className="md:text-base  xs:mt-[2px] sm:mt-1 lg:mt-1 xl:text-lg xl:mt-1 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 4xl:mt-3 5xl:text-4xl 5xl:mt-3 6xl:text-6xl 6xl:mt-4 7xl:text-7xl 7xl:mt-7 text-[10px] " />
        </button>
      </div>
      <div className="card w-full flex justify-content-center">
        <Dialog
          className=" w-[80%] sm:w-[70%] md:w-[62%] lg:w-[48%] xl:w-[40%] 2xl:w-[38%] 4xl:w-[45%] 5xl:w-[42%] 6xl:w-[45%] 7xl:w-[45%]"
          visible={visible}
          modal
          onHide={() => {
            if (!visible) return;
            setVisible(false);
          }}
          content={({ hide }) => (
            <form
              className=" w-[100%] flex flex-col px-5 py-10 4xl:py-16 5xl:py-24 6xl:py-28 7xl:py-32 shadow-xl shadow-gray-700 drop gap-3 4xl:gap-6 5xl:gap-8 6xl:gap-14 7xl:gap-16   items-center rounded-xl 5xl:rounded-3xl"
              onSubmit={handleSubmit}
              style={{ backgroundColor: "#202E41" }}
            >
              <div className=" w-[90%] flex justify-between pb-3 4xl:pb-4 5xl:pb-10 6xl:pb-20 7xl:pb-24 ">
                <h1 className=" text-white text-xl md:text-2xl 2xl:text-3xl 3xl:text-4xl 4xl:text-6xl 5xl:text-7xl 6xl:text-9xl 7xl:text-[136px] font-semibold">
                  Enroll Here
                </h1>
                <IoCloseOutline
                  className=" text-3xl 3xl:text-4xl 4xl:text-5xl 5xl:text-6xl 6xl:text-8xl 7xl:text-9xl text-white cursor-pointer"
                  onClick={(e) => hide(e)}
                />
              </div>

              <IconField
                iconPosition="right"
                className=" placeholder:pb-1 bg-[#27384f]  w-[90%] h-12 xs:h-14  sm:h-16 md:h-16 lg:h-[66px] xl:h-[66px] 3xl:h-20  3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16 rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
              >
                <InputIcon className="7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                  <IoPersonCircleOutline />
                </InputIcon>
                <InputText
                  name="Name"
                  value={detail.Name}
                  onChange={handleChange}
                  type="text"
                  v-model="value1"
                  placeholder="Name"
                  className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                />
              </IconField>
              <IconField
                iconPosition="right"
                className=" placeholder:pb-1 bg-[#27384f]  w-[90%] h-12 xs:h-14  sm:h-16 md:h-16 lg:h-[66px] xl:h-[66px] 3xl:h-20 3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16  rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
              >
                <InputIcon className="7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                  <MdOutlineEmail />
                </InputIcon>
                <InputText
                  name="Email"
                  value={detail.Email}
                  onChange={handleChange}
                  type="email"
                  v-model="value1"
                  placeholder="Email"
                  className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                />
              </IconField>

              <IconField
                iconPosition="right"
                className=" placeholder:pb-1 bg-[#27384f]  w-[90%] h-12 xs:h-14  sm:h-16 md:h-16 lg:h-[66px] xl:h-[66px] 3xl:h-20 3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16 rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
              >
                <InputIcon className="7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                  <IoBookOutline />
                </InputIcon>
                <InputText
                  name="Education"
                  value={detail.Education}
                  onChange={handleChange}
                  type="text"
                  v-model="value1"
                  placeholder="Education Qualification"
                  className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                />
              </IconField>
              <div className=" flex flex-col md:flex md:flex-row items-center w-[90%] gap-4 7xl:gap-9">
                <IconField
                  iconPosition="right"
                  className=" placeholder:pb-1 bg-[#27384f] w-full  2xl:w-[90%] h-12 xs:h-14  sm:h-16 md:h-16 lg:h-[66px]  xl:h-[66px] 3xl:h-20 3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16 rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
                >
                  <InputIcon className="7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                    <MdOutlinePhoneEnabled />
                  </InputIcon>
                  <InputText
                    name="Phone"
                    value={detail.Phone}
                    onChange={handleChange}
                    type="text"
                    v-model="value1"
                    placeholder="Phone No"
                    className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                  />
                </IconField>
                <IconField
                  iconPosition="right"
                  className=" placeholder:pb-1 bg-[#27384f]  w-full  2xl:w-[90%] h-12 xs:h-14  sm:h-16 lg:h-[66px]  xl:h-[66px] 3xl:h-20 3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16  rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
                >
                  <InputIcon className=" 7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                    <PiGraduationCapBold />
                  </InputIcon>
                  <InputText
                    name="YOP"
                    value={detail.YOP}
                    onChange={handleChange}
                    type="text"
                    v-model="value1"
                    placeholder="Graduation Year"
                    className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                  />
                </IconField>
              </div>

              <div className="flex align-items-center gap-2">
                <Button
                  type="submit"
                  text
                  className=" text-black  bg-[#FFB039] 2xl:w-48 2xl:h-14 3xl:w-64 3xl:h-20 4xl:w-[330px] 4xl:h-24 5xl:w-[400px] 5xl:h-32 6xl:w-[500px] 6xl:h-44  7xl:w-[600px] 7xl:h-52 xl:w-40 xl:h-14 lg:w-40 lg:h-14 md:w-36 md:h-12 sm:w-36 sm:h-12 xs:w-36 xs:h-12 w-36 h-12 md:text-xl  text-lg font-bold 2xl:text-2xl 3xl:text-4xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl 7xl:text-8xl 7xl:font-bold 4xl:mt-4 5xl:mt-10 6xl:mt-20 flex justify-around items-center   2xl:font-semibold rounded-xl 6xl:rounded-2xl 7xl:rounded-3xl mt-3 hover:shadow-xl hover:shadow-[#3d3d4b] transform duration-500 hover:scale-90 "
                >
                  
                  Enroll Now
                  <MdOutlineArrowForward className=" 7xl:mt-3" />
                </Button>
              </div>
            </form>
          )}
        ></Dialog>
      </div>
    </div>
  );
};
