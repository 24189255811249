
import './App.css';

import { Route,Routes } from 'react-router-dom';

import { ToastContainer } from 'react-toastify';
import { Multicloud } from './modules/Multicloud';

import "react-toastify/dist/ReactToastify.css";


import { PrimeReactProvider } from 'primereact/api';
import 'primeicons/primeicons.css';
import { Main } from './Main';
import { Contactus } from './common/contactus';
function App(){
  return(
    <PrimeReactProvider>
   <Routes>
    <Route path='/' element={<Main/>}>
    <Route index element={<Multicloud/>}/>
    <Route path='/contactus' element={<Contactus/>}/>
    </Route>
   </Routes>
   

   <ToastContainer/>
    </PrimeReactProvider>
 
  )
}


export default App;

