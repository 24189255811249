import React, { useState } from "react";
import { FaPlay } from "react-icons/fa";
import { IoPersonCircleOutline } from "react-icons/io5";
import { MdOutlinePhoneEnabled } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";
import { IconField } from "primereact/iconfield";
import { Button } from 'primereact/button';
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { PiGraduationCapBold } from "react-icons/pi";
import { IoBookOutline } from "react-icons/io5";
import { Dialog } from 'primereact/dialog';
import { MdOutlineArrowForward } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import axios from "axios";
import { toast } from "react-toastify";
import {  useNavigate } from "react-router-dom";

const testimonials = [
  {
    img: "/assets/Ellipse 85.png",
    content:
      "Mentor fox transformed my career! the personalized mentorship and practical advice i received helped me land my dream job.Highly recommeded!",
    name: "Abbas",
    work: "Media,Royal Enfield",
  },
  {
    img: "/assets/Ellipse 86.png",
    content:
      "Mentor fox transformed my career! the personalized mentorship and practical advice i received helped me land my dream job.Highly recommeded!",
    name: "Abbas",
    work: "Media,Royal Enfield",
  },
  {
    img: "/assets/Ellipse 87.png",
    content:
      "Mentor fox transformed my career! the personalized mentorship and practical advice i received helped me land my dream job.Highly recommeded!",
    name: "Abbas",
    work: "Media,Royal Enfield",
  },
];

export const Testimonial = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const initialstate = {
   
    Email: "",
    Phone: "",
    Name: "",
    Education: "",
    YOP: "",
    NotificationPermission: false,
  };
  
  const [detail, setdetail] = useState(initialstate);
 
  const handleChange = (event) => {
    const { name, value } = event.target;
    setdetail({ ...detail, [name]: value });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log( detail);
   
    await axios
      .post("http://localhost:4000/enroll", detail)
      .then((res) => {
        if (res.data) {
          toast.success("Enrolled successfully");
          setVisible(false)
        }
      })
      .catch((err) => console.log("not enrolled"))
      .finally(()=>{
        // setShowModal1(false)
      })
      
  };
 
  return (
    <div className=" w-full bg-[#0B192C] flex flex-col items-center gap-16 pt-20 sm:py-28 4xl:py-36 5xl:py-40 6xl:py-56 7xl:py-72 4xl:gap-36 5xl:gap-48 6xl:gap-72 7xl:gap-96">
      <div className=" flex flex-col items-center gap-14 md:gap-16 4xl:gap-40 6xl:gap-64 7xl:gap-80 ">
        <div className=" w-full  bg-[#FFB039] text-center py-10 md:py-20 4xl:py-32 5xl:py-44 6xl:py-52 7xl:py-80 flex gap-10  md:gap-24 xl:gap-24 4xl:gap-52 5xl:gap-64 6xl:gap-72 7xl:gap-[420px] flex-col items-center justify-center ">
          <h1 className=" text-2xl font-bold md:text-3xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl 7xl:text-9xl">
            Check out our proud Testimonials
          </h1>
          <div className=" w-[85%]  gap-12 hidden md:flex ">
            <div className=" flex justify-center md:w-[34%]  lg:w-[32%]  md:py-2 before:origin-center   hover: hover:transition-all hover:duration-500  hover:ease-out hover:shadow-lg hover:-mt-2 ">
              <img
                src="/assets/Ellipse 85.png"
                alt=""
                className=" md:w-20 xl:w-28  absolute z-10 md:-mt-8 lg:-mt-14 4xl:-mt-24 4xl:w-48 5xl:w-64 5xl:-mt-32 6xl:w-80 6xl:-mt-40 7xl:w-96 7xl:-mt-48"
              />
              <div className=" w-full md:h-96 lg:h-72 2xl:h-80 4xl:h-[450px] 5xl:h-[600px] 6xl:h-[700px] 7xl:h-[840px]  bg-white rounded-tl-3xl gap-5 4xl:gap-16 4xl:pt-8 5xl:gap-20 5xl:pt-12 7xl:gap-28 7xl:pt-20 rounded-br-3xl relative z-0 shadow-lg shadow-gray-500/50 justify-center items-center flex flex-col">
                <p className=" text-lg font-semibold md:w-[95%] lg:w-[90%] pt-8 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl 4xl:font-medium">
                  "Mentor fox transformed my career! the personalized mentorship
                  and practical advice i received helped me land my dream job.
                  Highly recommeded!"
                </p>
                <div className="  text-start w-[85%] 5xl:gap-6 flex flex-col">
                  <p className=" text-2xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl">Abbas</p>
                  <p className=" text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl 7xl:text-6xl">Media,Royal Enfield</p>
                </div>
              </div>
            </div>
            <div className=" flex justify-center md:w-[34%]  lg:w-[32%] md:py-2 before:origin-center   hover: hover:transition-all hover:duration-500  hover:ease-out hover:shadow-lg hover:-mt-2 ">
              <img
                src="/assets/Ellipse 86.png"
                alt=""
                className=" md:w-20 xl:w-28 absolute z-10 md:-mt-8 lg:-mt-14 4xl:-mt-24 4xl:w-48 5xl:w-64 5xl:-mt-32 6xl:w-80 6xl:-mt-40 7xl:w-96 7xl:-mt-48"
              />
              <div className=" w-full md:h-96 lg:h-72 2xl:h-80 4xl:h-[450px] 5xl:h-[600px] 6xl:h-[700px] 7xl:h-[840px]  bg-white rounded-tl-3xl gap-5 4xl:gap-16 4xl:pt-8 5xl:gap-20 5xl:pt-12 7xl:gap-28 7xl:pt-20 rounded-br-3xl relative z-0 shadow-lg shadow-gray-500/50 justify-center items-center flex flex-col">
                <p className=" text-lg font-semibold md:w-[95%] lg:w-[90%] 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl 4xl:font-medium pt-8">
                  "Mentor fox transformed my career! the personalized mentorship
                  and practical advice i received helped me land my dream job.
                  Highly recommeded!"
                </p>
                <div className="  text-start w-[85%] 5xl:gap-6 flex flex-col">
                  <p className=" text-2xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl">Abbas</p>
                  <p className=" text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl 7xl:text-6xl">Media,Royal Enfield</p>
                </div>
              </div>
            </div>
            <div className=" flex justify-center md:w-[34%]  lg:w-[32%] md:py-2 before:origin-center   hover: hover:transition-all hover:duration-500  hover:ease-out hover:shadow-lg hover:-mt-2">
              <img
                src="/assets/Ellipse 87.png"
                alt=""
                className=" md:w-20 xl:w-28 absolute z-10 md:-mt-8 lg:-mt-14 4xl:-mt-24 4xl:w-48 5xl:w-64 5xl:-mt-32 6xl:w-80 6xl:-mt-40 7xl:w-96 7xl:-mt-48"
              />
              <div className=" w-full md:h-96 lg:h-72 2xl:h-80 4xl:h-[450px] 5xl:h-[600px] 6xl:h-[700px] 7xl:h-[840px]  4xl:-mt-2 bg-white rounded-tl-3xl gap-5 4xl:gap-16 4xl:pt-8 5xl:gap-20 5xl:pt-12 7xl:gap-28 7xl:pt-20 rounded-br-3xl relative z-0 shadow-lg shadow-gray-500/50 justify-center items-center flex flex-col">
                <p className=" text-lg font-semibold md:w-[95%] lg:w-[90%] pt-8 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl 4xl:font-medium">
                  "Mentor fox transformed my career! the personalized mentorship
                  and practical advice i received helped me land my dream job.
                  Highly recommeded!"
                </p>
                <div className="  text-start w-[85%] 5xl:gap-6 flex flex-col">
                  <p className=" text-2xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl">Abbas</p>
                  <p className=" text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl 7xl:text-6xl">Media,Royal Enfield</p>
                </div>
              </div>
            </div>
          </div>
          <div className="flex md:hidden overflow-x-scroll w-[84%] gap-7 sm:gap-20 md:gap-7 pl-1">
            {testimonials.map((items) => (
              <div className=" flex justify-center w-[95%]  flex-shrink-0">
                <div className=" w-full h-96 bg-white rounded-tl-3xl gap-5 rounded-br-3xl relative z-0 shadow-lg shadow-gray-500/50 justify-center items-center flex flex-col">
                  <img src={items.img} alt="" className=" w-24 h-24   " />
                  <p className=" text-lg font-semibold w-[90%] ">
                    {items.content}
                  </p>
                  <div className="  text-start w-[85%]">
                    <p className=" text-2xl">{items.name}</p>
                    <p className=" text-xl">{items.work}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          
          className={`sm:flex hidden lg:w-[70%] w-[80%] 4xl:w-[80%]  h-[80px] xl:h-40 lg:h-32 4xl:h-56 5xl:h-72 6xl:h-96 7xl:h-[450px] gap-1 
           
          `}
        >
          <div className=" w-[20%]  h-full bg-[#FFB039] rounded-s-3xl flex xl:gap-2 md:gap-1 gap-1 4xl:gap-4 5xl:gap-6 6xl:gap-8 6xl:pt-16 lg:pt-3   items-center 4xl:pt-10  flex-col md:items-start lg:items-center   sm:pl-3 text-center justify-start pt-3 md:pt-2 xl:pt-4">
            <p className=" xl:-ml-8 text-xs md:text-lg font-semibold lg:text-2xl xl:text-3xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl 7xl:text-8xl text-center w-[80%] ">
              95%
            </p>

            <p className=" xl:-ml-14 text-xs md:text-base  lg:text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl 7xl:text-7xl 7xl:leading-normal  6xl:leading-normal  5xl:leading-normal  text-center font-medium w-[80%] md:w-[80%] xl:w-[30%]">
              Placement success
            </p>
          </div>
          <div className=" w-[20%]  h-full bg-[#FFB039] flex xl:gap-2 md:gap-1 gap-1 4xl:gap-4 5xl:gap-6 6xl:gap-8 6xl:pt-16  lg:pt-3  flex-col items-center 4xl:pt-10 md:items-start lg:items-center pl-2 sm:pl-5 justify-start pt-3 md:pt-2 xl:pt-4">
            <p className=" text-xs md:text-lg font-semibold lg:text-2xl xl:text-3xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl 7xl:text-8xl text-center w-[80%]">

              5 LPA
            </p>
            <p className=" text-xs md:text-base  lg:text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl 7xl:text-7xl 7xl:leading-normal  6xl:leading-normal  5xl:leading-normal   text-center font-medium w-[60%] md:w-[80%] xl:w-[30%]">
              Average CTC
            </p>
          </div>
          <div className=" w-[20%]  h-full bg-[#FFB039]  flex xl:gap-2 md:gap-1 gap-1 4xl:gap-4 5xl:gap-6 6xl:gap-8 lg:pt-3 6xl:pt-16  flex-col md:items-start 4xl:pt-10 lg:items-center pl-2 sm:pl-5  md:pl-0 justify-start pt-3 md:pt-2 xl:pt-4">
            <p className=" text-xs md:text-lg font-semibold lg:text-2xl xl:text-3xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl 7xl:text-8xl text-center w-[80%]">
              12 LPA
            </p>
            <p className=" text-xs md:text-base  lg:text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl 7xl:text-7xl 7xl:leading-normal  6xl:leading-normal  5xl:leading-normal   text-center font-medium w-[80%] md:w-[80%] xl:w-[30%]">
              Highest CTC
            </p>
          </div>
          <div className=" w-[20%]  h-full bg-[#FFB039]  flex xl:gap-2 md:gap-1 gap-1 4xl:gap-4 5xl:gap-6 6xl:gap-8 lg:pt-3 6xl:pt-16   flex-col md:items-start 4xl:pt-10 lg:items-center pl-2 sm:pl-5 justify-start pt-3 md:pt-2 xl:pt-4">
            <p className=" text-xs md:text-lg font-semibold lg:text-2xl xl:text-3xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl 7xl:text-8xl text-center w-[80%]">
              500+
            </p>
            <p className=" text-xs md:text-base  lg:text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl 7xl:text-7xl 7xl:leading-normal  6xl:leading-normal  5xl:leading-normal   text-center font-medium w-[80%] md:w-[80%] xl:w-[30%]">
              Hiring Partners
            </p>
          </div>

          <div className=" w-[20%]  h-full bg-[#FFB039] rounded-e-3xl text-center 4xl:gap-4 5xl:gap-6 flex xl:gap-2 lg:pt-3 6xl:gap-8 6xl:pt-16 md:gap-1 gap-1 4xl:pt-10  flex-col md:items-start lg:items-center pl-2 sm:pl-5 justify-start pt-3 md:pt-2 xl:pt-4">
            <p className="text-xs md:text-lg font-semibold lg:text-2xl xl:text-3xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl 7xl:text-8xl text-center w-[80%]">
              60%
            </p>
            <p className="xl:-ml-4 text-xs md:text-base  lg:text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl 7xl:text-7xl 7xl:leading-normal 6xl:leading-normal  5xl:leading-normal  text-center font-medium w-[80%] md:w-[80%] xl:w-[30%]">
              Average Salary Hike
            </p>
          </div>
        </div>
        <div className=" sm:hidden w-full flex flex-col justify-center items-center gap-10">
          <div className=" w-[84%] flex gap-10">
            <div className="bg-[#FFB039] w-[45%] h-44 rounded-2xl flex flex-col gap-1 justify-center items-center">
              <p className=" text-3xl  font-extrabold  text-center w-[80%]">
                95%
              </p>

              <p className=" text-xl  text-center font-bold w-[80%]">
                Placement success
              </p>
            </div>
            <div className="bg-[#FFB039] w-[45%] h-44 rounded-2xl flex flex-col gap-1 justify-center items-center">
              <p className=" text-3xl  font-extrabold  text-center w-[80%]">
                {" "}
                5 LPA
              </p>
              <p className=" text-xl  text-center font-bold w-[80%]">
                Average CTC
              </p>
            </div>
          </div>
          <div className=" w-[84%] flex gap-10">
            <div className="bg-[#FFB039] w-[45%] h-44 rounded-2xl flex flex-col gap-1 justify-center items-center">
              <p className=" text-3xl  font-extrabold  text-center w-[80%]">
                12 LPA
              </p>
              <p className=" text-xl  text-center font-bold w-[80%]">
                Highest CTC
              </p>
            </div>
            <div className="bg-[#FFB039] w-[45%] h-44 rounded-2xl flex flex-col gap-1 justify-center items-center">
              {" "}
              <p className=" text-3xl  font-extrabold  text-center w-[80%]">
                500+
              </p>
              <p className=" text-xl  text-center font-bold w-[80%]">
                Hiring Partners
              </p>
            </div>
          </div>
          <div className=" w-[84%] flex gap-10">
            <div className="bg-[#FFB039] w-[45%] h-44 rounded-2xl flex flex-col gap-1 justify-center items-center">
              <p className=" text-3xl  font-extrabold  text-center w-[80%]">
                60%
              </p>
              <p className=" text-xl  text-center font-bold w-[80%]">
                Average Salary Hike
              </p>
            </div>
            <div className="bg-[#FFB039] w-[45%] h-44 rounded-2xl flex flex-col gap-1 justify-center items-center">
              <p className=" text-3xl  font-extrabold  text-center w-[80%]">
                12+
              </p>
              <p className=" text-xl  text-center font-bold w-[80%]">
                our Mentors Average years of Experience
              </p>
            </div>
          </div>
        </div>
        <div className=" w-full flex justify-center xl:h-[70px]">
          
            <button onClick={() => setVisible(true)} className=" 7xl:w-[670px] 7xl:h-64 6xl:w-[480px] 6xl:h-48 5xl:w-96 5xl:h-36 4xl:w-80 4xl:h-28 3xl:w-64 3xl:h-20 2xl:w-52 xl:h-16 xl:w-40 2xl:h-[60px]  lg:w-36 lg:h-14 md:w-36 md:h-14 sm:w-32 sm:h-12 sm:text-base xs:w-28 xs:h-10 xs:text-sm  w-24 h-8 6xl:gap-7 7xl:gap-12  rounded-lg 5xl:rounded-xl 7xl:rounded-3xl items-center justify-center   md:gap-3 sm:gap-3 xs:gap-2 gap-1 md:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl 4xl:text-[37px] 5xl:text-5xl 6xl:text-7xl 7xl:text-8xl text-xs pb-1  5xl:gap-5    hover:bg-[#f79e1a] transition-all duration-500  bg-[#FFB039] flex font-bold  ">
              Enroll Now <FaPlay className="md:text-base  xs:mt-[2px] sm:mt-1 lg:mt-1 xl:text-lg xl:mt-1 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 4xl:mt-3 5xl:text-4xl 5xl:mt-3 6xl:text-6xl 6xl:mt-4 7xl:text-7xl 7xl:mt-7 text-[10px] " />
            </button>
       
        </div>
        <div className="card w-full flex justify-content-center">
        <Dialog
            className=" w-[80%] sm:w-[70%] md:w-[62%] lg:w-[48%] xl:w-[40%] 2xl:w-[38%] 4xl:w-[45%] 5xl:w-[42%] 6xl:w-[45%] 7xl:w-[45%]"
                visible={visible}
                modal
                onHide={() => {if (!visible) return; setVisible(false); }}
                content={({ hide }) => (
                    <form className=" w-[100%] flex flex-col px-5 py-10 4xl:py-16 5xl:py-24 6xl:py-28 7xl:py-32 shadow-xl shadow-gray-700 drop gap-3 4xl:gap-6 5xl:gap-8 6xl:gap-14 7xl:gap-16   items-center rounded-xl 5xl:rounded-3xl" onSubmit={handleSubmit} style={{ backgroundColor:"#202E41"  }}>
                      <div className=" w-[90%] flex justify-between pb-3 4xl:pb-4 5xl:pb-10 6xl:pb-20 7xl:pb-24 ">
                      <h1 className=" text-white text-xl md:text-2xl 2xl:text-3xl 3xl:text-4xl 4xl:text-6xl 5xl:text-7xl 6xl:text-9xl 7xl:text-[136px] font-semibold">Enroll Here</h1>
                      <IoCloseOutline className=" text-3xl 3xl:text-4xl 4xl:text-5xl 5xl:text-6xl 6xl:text-8xl 7xl:text-9xl text-white cursor-pointer" onClick={(e) => hide(e)} />
                      </div>
                      
                         <IconField
                            
                            iconPosition="right"
                            className=" placeholder:pb-1 bg-[#27384f]  w-[90%] h-12 xs:h-14  sm:h-16 md:h-16 lg:h-[66px] xl:h-[66px] 3xl:h-20  3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16 rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
                          >
                            <InputIcon className="7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                              <IoPersonCircleOutline />
                            </InputIcon>
                            <InputText
                            name="Name"
                            value={detail.Name}
                            onChange={handleChange}
                            type="text"
                              v-model="value1"
                              placeholder="Name"
                              className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                            />
                          </IconField>
                         <IconField
                           
                            iconPosition="right"
                            className=" placeholder:pb-1 bg-[#27384f]  w-[90%] h-12 xs:h-14  sm:h-16 md:h-16 lg:h-[66px] xl:h-[66px] 3xl:h-20 3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16  rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
                          >
                            <InputIcon className="7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                              <MdOutlineEmail />
                            </InputIcon>
                            <InputText
                             name="Email"
                             value={detail.Email}
                             onChange={handleChange}
                             type="email"
                              v-model="value1"
                              placeholder="Email"
                              className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                            />
                          </IconField>
                          
                          <IconField
                           
                            iconPosition="right"
                            className=" placeholder:pb-1 bg-[#27384f]  w-[90%] h-12 xs:h-14  sm:h-16 md:h-16 lg:h-[66px] xl:h-[66px] 3xl:h-20 3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16 rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
                          >
                            <InputIcon className="7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                              < IoBookOutline />
                            </InputIcon>
                            <InputText
                             name="Education"
                             value={detail.Education}
                             onChange={handleChange}
                             type="text"
                              v-model="value1"
                              placeholder="Education Qualification"
                              className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                            />
                          </IconField>
                          <div className=" flex flex-col md:flex md:flex-row items-center w-[90%] gap-4 7xl:gap-9">
                          <IconField
                           
                            iconPosition="right"
                            className=" placeholder:pb-1 bg-[#27384f] w-full  2xl:w-[90%] h-12 xs:h-14  sm:h-16 md:h-16 lg:h-[66px]  xl:h-[66px] 3xl:h-20 3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16 rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
                          >
                            <InputIcon className="7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                              < MdOutlinePhoneEnabled/>
                            </InputIcon>
                            <InputText
                             name="Phone"
                             value={detail.Phone}
                             onChange={handleChange}
                             type="text"
                              v-model="value1"
                              placeholder="Phone No"
                              className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                            />
                          </IconField>
                          <IconField
                           
                          
                            iconPosition="right"
                            className=" placeholder:pb-1 bg-[#27384f]  w-full  2xl:w-[90%] h-12 xs:h-14  sm:h-16 lg:h-[66px]  xl:h-[66px] 3xl:h-20 3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16  rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
                          >
                            <InputIcon className=" 7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                              <PiGraduationCapBold />
                            </InputIcon>
                            <InputText
                             name="YOP"
                             value={detail.YOP}
                             onChange={handleChange}
                               type="text"
                              v-model="value1"
                              placeholder="Graduation Year"
                              className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                            />
                          </IconField>

                          </div> 
                          
                        <div className="flex align-items-center gap-2">
                            <Button  type="submit"  text className=" text-black  bg-[#FFB039] 2xl:w-48 2xl:h-14 3xl:w-64 3xl:h-20 4xl:w-[330px] 4xl:h-24 5xl:w-[400px] 5xl:h-32 6xl:w-[500px] 6xl:h-44  7xl:w-[600px] 7xl:h-52 xl:w-40 xl:h-14 lg:w-40 lg:h-14 md:w-36 md:h-12 sm:w-36 sm:h-12 xs:w-36 xs:h-12 w-36 h-12 md:text-xl  text-lg font-bold 2xl:text-2xl 3xl:text-4xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl 7xl:text-8xl 7xl:font-bold 4xl:mt-4 5xl:mt-10 6xl:mt-20 flex justify-around items-center   2xl:font-semibold rounded-xl 6xl:rounded-2xl 7xl:rounded-3xl mt-3 hover:shadow-xl hover:shadow-[#3d3d4b] transform duration-500 hover:scale-90 "> Enroll Now<MdOutlineArrowForward className=" 7xl:mt-3"/></Button>
                        </div>
                    </form>
                )}
            ></Dialog>  
        </div>
      </div>
      {/* <div className=" flex flex-col gap-20 4xl:gap-32 5xl:gap-48 6xl:gap-52 7xl:gap-72   w-full  items-center ">
        <h1 className=" w-[90%] text-center text-xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl 4xl:text-7xl 6xl:text-8xl 7xl:text-9xl text-white font-semibold ">
          Customized Placement Support - Assured Referrals
        </h1>
        <div className=" w-full flex flex-col items-center gap-16">
          <div className=" grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 w-[85%] gap-16">
            <div className=" h-32 xs:h-32 sm:h-40 md:h-56 4xl:h-72 4xl:p-7 5xl:h-96 5xl:p-7 6xl:h-[450px] 6xl:p-10 7xl:h-[500px] 7xl:p-14 bg-[#202E41] rounded-3xl p-3 flex md:flex-col items-center md:items-start 6xl:gap-8 5xl:gap-5  gap-3">
              <div className="  w-[34%] sm:w-[30%] md:w-[22%] h-[90%] xs:h-[90%] sm:h-[90%] md:h-[40%] lg:h-[40%] xl:h-[50%] 3xl:h-[55%] 4xl:h-[60%] bg-[#33445B] rounded-2xl">
              </div>
              <p className=" text-white text-xl xs:text-2xl  sm:text-3xl md:text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl font-medium ">
                1:1 career coaching session with top industrial professionals
              </p>
            </div>
            <div className=" h-32 xs:h-32 sm:h-40 md:h-56 4xl:h-72 4xl:p-7 5xl:h-96 5xl:p-7 6xl:h-[450px] 6xl:p-10 7xl:h-[500px] 7xl:p-14 bg-[#202E41] rounded-3xl p-3 flex md:flex-col items-center md:items-start 6xl:gap-8 5xl:gap-5    gap-3">
              <div className="  w-[34%] sm:w-[30%] md:w-[22%] h-[90%] xs:h-[90%] sm:h-[90%] md:h-[40%] lg:h-[40%] xl:h-[50%] 3xl:h-[55%] 4xl:h-[60%] bg-[#33445B] rounded-2xl">
              </div>
              <p className=" text-white text-xl xs:text-2xl  sm:text-3xl md:text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl font-medium ">
                1:1 career coaching session with top industrial professionals
              </p>
            </div>
            <div className=" h-32 xs:h-32 sm:h-40 md:h-56 4xl:h-72 4xl:p-7 5xl:h-96 5xl:p-7 6xl:h-[450px] 6xl:p-10 7xl:h-[500px] 7xl:p-14 bg-[#202E41] rounded-3xl p-3 flex md:flex-col items-center md:items-start 6xl:gap-8 5xl:gap-5   gap-3">
              <div className="  w-[34%] sm:w-[30%] md:w-[22%] h-[90%] xs:h-[90%] sm:h-[90%] md:h-[40%] lg:h-[40%] xl:h-[50%] 3xl:h-[55%] 4xl:h-[60%] bg-[#33445B] rounded-2xl">
              </div>
              <p className=" text-white text-xl xs:text-2xl  sm:text-3xl md:text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl font-medium ">
                1:1 career coaching session with top industrial professionals
              </p>
            </div>

            <div className=" h-32 xs:h-32 sm:h-40 md:h-56 4xl:h-72 4xl:p-7 5xl:h-96 5xl:p-7 6xl:h-[450px] 6xl:p-10 7xl:h-[500px] 7xl:p-14 bg-[#202E41] rounded-3xl p-3 flex md:flex-col items-center md:items-start 6xl:gap-8 5xl:gap-5    gap-3">
              <div className="  w-[34%] sm:w-[30%] md:w-[22%] h-[90%] xs:h-[90%] sm:h-[90%] md:h-[40%] lg:h-[40%] xl:h-[50%] 3xl:h-[55%] 4xl:h-[60%] bg-[#33445B] rounded-2xl">
              </div>
              <p className=" text-white text-xl xs:text-2xl  sm:text-3xl md:text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl font-medium ">
                1:1 career coaching session with top industrial professionals
              </p>
            </div>
            <div className=" h-32 xs:h-32 sm:h-40 md:h-56 4xl:h-72 4xl:p-7 5xl:h-96 5xl:p-7 6xl:h-[450px] 6xl:p-10 7xl:h-[500px] 7xl:p-14  bg-[#202E41] rounded-3xl p-3 flex md:flex-col items-center md:items-start 6xl:gap-8 5xl:gap-5    gap-3">
              <div className="  w-[34%] sm:w-[30%] md:w-[22%] h-[90%] xs:h-[90%] sm:h-[90%] md:h-[40%] lg:h-[40%] xl:h-[50%] 3xl:h-[55%] 4xl:h-[60%]  bg-[#33445B] rounded-2xl">
              </div>
              <p className=" text-white text-xl xs:text-2xl  sm:text-3xl md:text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl font-medium ">
                1:1 career coaching session with top industrial professionals
              </p>
            </div>
            <div className=" h-32 xs:h-32 sm:h-40 md:h-56 4xl:h-72 4xl:p-7 5xl:h-96 5xl:p-7 6xl:h-[450px] 6xl:p-10 7xl:h-[500px] 7xl:p-14 bg-[#202E41] rounded-3xl p-3 flex md:flex-col items-center md:items-start 6xl:gap-8 5xl:gap-5    gap-3">
              <div className="  w-[34%] sm:w-[30%] md:w-[22%] h-[90%] xs:h-[90%] sm:h-[90%] md:h-[40%] lg:h-[40%] xl:h-[50%] 3xl:h-[55%] 4xl:h-[60%] bg-[#33445B] rounded-2xl">
              </div>
              <p className=" text-white text-xl xs:text-2xl  sm:text-3xl md:text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl font-medium ">
                1:1 career coaching session with top industrial professionals
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
