import React from 'react'

export const Contactus = () => {
  return (
    <div className='w-full bg-[#0B192C]'>
        <div className=' w-[50%]'>
            
        </div>
        <div className=' w-[50%]'></div>

    </div>
  )
}
