import React from 'react'
import "../font/Sentic Text.otf"
export const Footer = () => {
  return (
    <div className=' w-full  bg-[#202E41] flex flex-col 4xl:justify-evenly  md:flex-row py-10 pl-10 xm:py-12 xm:pl-12 xm:pr-5 xs:py-16 xs:px-16  sm:py-16 sm:px-16 md:py-10 md:px-10 lg:py-12 lg:px-12 xl:py-16 xl:px-16 2xl:py-20 2xl:px-32 3xl:py-24 3xl:px-40 4xl:py-24 4xl:px-40 5xl:py-28 5xl:px-44 6xl:py-32 6xl:px-48 7xl:py-40 7xl:px-52 gap-12 xm:gap-16 xs:gap-16 sm:gap-20 md:gap-20 lg:gap-32 2xl:gap-40 xl:gap-40 3xl:gap-48 font-[light]'>
        <div className=' flex gap-2 xm:gap-8  xs:gap-20 sm:gap-40 md:gap-20 lg:gap-32  xl:gap-40 2xl:gap-40 3xl:gap-48 4xl:gap-72 5xl:gap-80 6xl:gap-96 7xl:gap-[400px] '>
        <div className=' flex flex-col gap-3 xm:gap-4 xs:gap-4 sm:gap-5  md:gap-5 lg:gap-5  xl:gap-5 2xl:gap-5 3xl:gap-8 4xl:gap-14 5xl:gap-20 6xl:gap-24 7xl:gap-28  '>
            <div className=' flex items-center  '>
            <img src="/assets/image 1391.png" alt="" className=' w-12 xm:w-14 xs:w-14 sm:w-16 md:w-14 lg:w-12 xl:w-14 2xl:w-16 3xl:w-20 4xl:w-24 5xl:w-44 6xl:w-52 7xl:w-64' />
            <h1 className='text-white text-xl xm:text-2xl xs:text-3xl sm:text-3xl md:text-2xl lg:text-2xl xl:text-3xl 2xl:text-4xl 3xl:text-5xl 4xl:text-6xl 5xl:text-8xl 6xl:text-9xl 7xl:text-[132px] font-sans font-bold '>MentorFox</h1>
            </div>
            <p className='  text-white xm:text-sm xs:text-lg sm:text-xl lg:text-xl xl:text-xl 2xl:text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-6xl 6xl:text-7xl 7xl:text-7xl 4xl:leading-normal 6xl:leading-normal 7xl:leading-normal 5xl:leading-normal'>#40, First Floor,<br/> Rajiv Gandhi Salai, OMR,<br/> Sholinganallur, Chennai-600119</p>
        </div>
        <div className='flex flex-col gap-3 xm:gap-4 xs:gap-4 sm:gap-5   md:gap-5 lg:gap-5 xl:gap-5 2xl:gap-5 3xl:gap-8 4xl:gap-14 4xl:leading-normal 5xl:leading-normal 5xl:gap-20 6xl:gap-24 7xl:gap-28 mt-3 xm:mt-4 xs:mt-4 sm:mt-5  md:mt-4 lg:mt-4  xl:mt-4 2xl:mt-5 3xl:mt-7 4xl:mt-5 5xl:mt-16 6xl:mt-20 7xl:mt-32'>
            <h1 className=' text-white xm:text-base xs:text-lg sm:text-xl xl:text-xl 2xl:text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-6xl 6xl:text-7xl 7xl:text-7xl font-semibold'>COURSES</h1>
           <ul className=' text-white xm:text-base xs:text-base xl:text-lg 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl flex flex-col 4xl:gap-2 5xl:gap-6 6xl:gap-8'>
                <li>Cloud Computing</li>
                <li>DevOps</li>
                <li>Software Development</li>
                <li>Software Testing</li>
           </ul>
        </div>
        </div>
        <div className=' flex md:mt-4 lg:mt-4 xl:mt-4 2xl:mt-5 3xl:mt-7 4xl:mt-5 5xl:mt-16 6xl:mt-20 7xl:mt-32 gap-16  xm:gap-24 xs:gap-48 sm:gap-[266px] md:gap-20 lg:gap-32 xl:gap-40 2xl:gap-40 3xl:gap-48 4xl:gap-72 5xl:gap-80 6xl:gap-96 7xl:gap-[400px]'>
            <div className='flex flex-col gap-3 xm:gap-4 xs:gap-4 sm:gap-5 md:gap-5 lg:gap-5  xl:gap-5 2xl:gap-5 3xl:gap-8 4xl:gap-14 4xl:leading-normal 5xl:gap-20 6xl:gap-24 7xl:gap-28'>
                <h1 className='text-white xm:text-base xs:text-lg  sm:text-xl xl:text-xl 2xl:text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-6xl 6xl:text-7xl 7xl:text-7xl font-semibold'>MENTORFOX</h1>
                <ul className=' xm:text-base xs:text-base text-white xl:text-lg 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl flex flex-col 4xl:gap-2 5xl:gap-6 6xl:gap-8'>
                <li>About Us</li>
                <li>Privacy and Policy</li>
                <li>Retun & Refund Policy</li>
                <li>Shipping and Delivery</li>
                <li>Terms and Conditions</li>
                </ul>
            </div>
            <div className='flex flex-col gap-3 xm:gap-4  xs:gap-4 sm:gap-5 md:gap-5 lg:gap-5  xl:gap-5 2xl:gap-5 3xl:gap-8 4xl:gap-14 4xl:leading-normal 5xl:gap-20 6xl:gap-24 7xl:gap-28'>
                <h1 className='text-white xm:text-base xs:text-lg  sm:text-xl xl:text-xl 2xl:text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-6xl 6xl:text-7xl 7xl:text-7xl font-semibold'>FOR BUSINESS</h1>
                <ul className='text-white xm:text-base xs:text-base xl:text-lg 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl flex flex-col 4xl:gap-2 5xl:gap-6 6xl:gap-8'>
                <li>Hire from us</li>
                </ul >
                <h1 className='text-white xm:text-base xs:text-lg  xl:text-xl 2xl:text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-6xl 6xl:text-6xl 7xl:text-7xl font-semibold'>FOR BUSINESS</h1>
                <ul className='text-white xm:text-base xs:gap-4  xs:text-base xl:text-lg 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl flex flex-col 4xl:gap-2 5xl:gap-6 6xl:gap-8'>
                <li>7094 304 304</li>
                <li>curious@mentorfox.io</li>
                </ul>
            </div>

        </div>
        {/* <div className=' w-[90%] h-[90%] grid grid-cols-2 lg:grid-cols-4 sm:gap-5 md:gap-8 gap-4 4xl:gap-20 5xl:gap-24 6xl:gap-32 7xl:gap-40 justify-center ' >
        <div className=' flex flex-col justify-center md:gap-4 lg:gap-5 '>
            <div className=' flex items-center '>
            <img src="/assets/image 139.png" alt="" className=' w-20 sm:w-24 md:w-28 lg:w-24 xl:w-28 4xl:w-44 5xl:w-64 6xl:w-80 7xl:w-96 ' />
            <h1 className='text-white lg:text-3xl xl:text-4xl md:text-4xl sm:text-3xl text-2xl 4xl:text-5xl 4xl:-ml-5 5xl:text-7xl 5xl:-ml-8 6xl:text-8xl 6xl:-ml-10 7xl:text-9xl 7xl:-ml-12 font-bold font-sans -ml-3'>MentorFox</h1>
            </div>
            <p className=' text-base sm:text-xl md:text-xl lg:text-xl xl:text-xl 2xl:text-2xl    4xl:text-4xl 5xl:text-5xl 5xl:pl-12 5xl:leading-normal 6xl:text-6xl 6xl:pl-16 6xl:leading-normal 7xl:text-7xl 7xl:pl-16 7xl:leading-normal font-medium text-white pl-6'>#40, First Floor,<br/> Rajiv Gandhi Salai, OMR,<br/> Sholinganallur, Chennai-600119</p>
        </div>
        <div className='  flex flex-col gap-2 mt-3  md:gap-4 lg:gap-14 xl:gap-6 2xl:gap-8 pl-5 5xl:gap-16 6xl:gap-28 7xl:gap-32 sm:pl-14 md:pl-10 lg:pl-0 xl:pl-10 md:ml-10  md:mt-6    justify-center  '>
            <h1 className=' text-base sm:text-lg md:text-xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl font-semibold text-white  '>COURSES</h1>
            <ul className=' text-base text-white sm:text-lg 4xl:text-2xl 5xl:text-4xl 6xl:text-5xl 7xl:text-6xl 5xl:gap-5 7xl:gap-10 flex flex-col'>
                <li>Cloud Computing</li>
                <li>DevOps</li>
                <li>Software Development</li>
                <li>Software Testing</li>
            </ul>
        </div>
        <div className=' flex flex-col justify-center lg:pl-1  md:pl-5 sm:pl-6 gap-3 sm:gap-3 5xl:gap-16 6xl:gap-28 7xl:gap-32 7xl:pt-32 pl-6 pt-5   md:gap-3 lg:gap-6  '>
        <h1 className=' text-base sm:text-lg md:text-xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl font-semibold text-white'></h1>
            <ul className=' text-white text-base 4xl:text-2xl 5xl:text-4xl 6xl:text-5xl 7xl:text-6xl 5xl:gap-5 7xl:gap-10  flex flex-col sm:text-lg'>
                <li>About Us</li>
                <li>Privacy and Policy</li>
                <li>Retun & Refund Policy</li>
                <li>Shipping and Delivery</li>
                <li>Terms and Conditions</li>

            </ul>
        </div>
        <div className=' flex flex-col justify-center mb-3 2xl:gap-2 lg:gap-2 xl:gap-2 5xl:gap-12 6xl:gap-14 7xl:gap-24 7xl:pt-16 pl-6 sm:pl-14 lg:pl-0 xl:pl-10 xl:mt-7 lg:mt-7 md:ml-6  md:mt-6 sm:mt-4  xs:mt-5 gap-8 text-white  '>
            <div className='flex flex-col gap-6 xs:gap-2 sm:gap-5 2xl:gap-2 lg:gap-2 xl:gap-2 5xl:gap-12 6xl:gap-16 7xl:gap-20 '>
            <h1 className='text-base sm:text-lg md:text-xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl font-semibold'>FOR BUSINESS</h1>
            <p className='text-base sm:text-lg 4xl:text-2xl 5xl:text-4xl 6xl:text-5xl 7xl:text-6xl 5xl:gap-5'>Hire from us</p>
            </div>
            <div className='flex flex-col gap-8 xm:gap-6  xs:gap-2 sm:gap-5 2xl:gap-2 lg:gap-2 xl:gap-2 5xl:gap-12 6xl:gap-16 7xl:gap-20'>
            <h1 className='text-base sm:text-lg md:text-xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl font-semibold'>CONTACT US</h1>
            <ul className='text-base sm:text-lg 4xl:text-2xl 5xl:text-4xl 6xl:text-5xl 7xl:text-6xl 5xl:gap-5 7xl:gap-10 flex flex-col'>
                <li>7094 304 304</li>
                <li>curious@mentorfox.io</li>
            </ul>
            </div>
        </div>
        </div> */}
    </div>
  )
}
