import React from "react";
import { useEffect, useState, useRef } from "react";
import { FaPlay } from "react-icons/fa";

export const Aboutprogram = () => {
 
  return (
    <div
     
      className={`  w-full bg-[#202E41]  flex  flex-col items-center py-10 md:py-20 gap-10 md:gap-20 4xl:py-36  4xl:gap-32 5xl:py-44  5xl:gap-44 6xl:py-56  6xl:gap-56 7xl:py-64  7xl:gap-64 `}
    >
      <div
        className={`text-center flex flex-col gap-8 lg:gap-20 4xl:gap-32 5xl:gap-44 6xl:gap-56 7xl:gap-64 w-[82%] transition-opacity ease-in-out delay-200 duration-[3000ms]`}
      >
        <h1 className=" text-white text-2xl sm:text-2xl lg:text-4xl 4xl:text-7xl 5xl:text-8xl 6xl:text-9xl 7xl:text-[138px]  font-black">
         
          About the Program
        </h1>
        <p className=" text-white text-xs xs:text-sm sm:text-base  md:text-lg  lg:text-xl 2xl:text-2xl 4xl:text-4xl 5xl:text-5xl 6xl:text-7xl 5xl:leading-loose 4xl:leading-loose 6xl:leading-loose   leading-6 md:leading-9">
          Master Multi-Cloud & DevOps with MentorFox. Gain hands-on experience
          with industrial experts. Secure guaranteed placements in top
          companies. Master Multi-Cloud & DevOps with MentorFox. Gain hands-on
          experience with industrial experts. Secure guaranteed placements in
          top companies. Master Multi-Cloud & DevOps with MentorFox. Gain
          hands-on experience with industrial experts. Secure guaranteed
          placements in top companies Master Multi-Cloud & DevOps with
          MentorFox.
        </p>
      </div>
      <div
        className={` hidden w-[85%] lg:w-[84%] 4xl:w-[82%] md:flex lg:ml-12 xl:ml-0 md:ml-10  text-white items-center gap-3 xl:gap-12 4xl:gap-16 5xl:gap-20 6xl:gap-24 7xl:gap-28 transition-opacity ease-in-out delay-200 duration-[3000ms] 
          
        `}
      >
        <div className=" md:w-[20%] w-[40%] h-20 md:h-32 2xl:h-40 4xl:h-56 5xl:h-72 6xl:h-96 7xl:h-[400px] rounded-lg 7xl:rounded-3xl bg-[#33445B] border-2 border-white text-white flex justify-center items-center text-[10px] md:text-lg lg:text-xl font-medium text-center">
          <p className=" w-[80%] 2xl:text-2xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl 6xl:leading-normal 7xl:leading-normal">Starting the Program </p>
        </div>
        <FaPlay className=" text-2xl 4xl:text-3xl 5xl:text-5xl 6xl:text-7xl" />
        <div className=" md:w-[20%] w-[42%] h-20 md:h-32 rounded-lg 2xl:h-40 4xl:h-56 5xl:h-72 6xl:h-96 7xl:h-[400px] 7xl:rounded-3xl bg-[#33445B] border-2 border-white text-white flex justify-center items-center text-[10px] md:text-lg lg:text-xl font-medium text-center">
          <p className=" w-[80%] 2xl:text-2xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl 6xl:leading-normal 7xl:leading-normal">3 Months completely Offline course </p>
        </div>
        <FaPlay className=" text-2xl 4xl:text-3xl 5xl:text-5xl 6xl:text-7xl" />
        <div className=" md:w-[20%] w-[40%] h-20 md:h-32 rounded-lg 2xl:h-40 4xl:h-56 5xl:h-72 6xl:h-96 7xl:h-[400px] 7xl:rounded-3xl bg-[#33445B] border-2 border-white text-white flex justify-center items-center text-[10px] md:text-lg lg:text-xl font-medium text-center">
          <p className=" w-[80%] 2xl:text-2xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl 6xl:leading-normal 7xl:leading-normal">3 Months Placement Assistance </p>
        </div>
        <FaPlay className=" text-2xl 4xl:text-3xl 5xl:text-5xl 6xl:text-7xl" />
        <div className=" md:w-[20%] w-[40%] h-20 md:h-32 rounded-lg 2xl:h-40 4xl:h-56 5xl:h-72 6xl:h-96 7xl:h-[400px] 7xl:rounded-3xl  bg-[#33445B] border-2 border-white text-white flex justify-center items-center text-[10px] md:text-lg lg:text-xl font-medium text-center">
          <p className=" w-[80%] 2xl:text-2xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl 6xl:leading-normal 7xl:leading-normal">Landing your job </p>
        </div>
      </div>
      <div
        className={`w-[84%] lg:w-[84%] flex lg:ml-12 xl:ml-0 md:ml-10 md:hidden text-white items-center gap-3 xl:gap-12 transition-opacity ease-in-out delay-200 duration-[3000ms] 
        
        `}
      >
        <div className=" flex flex-col gap-7 xs:gap-9 sm:gap-11 items-center">
          <div className=" w-full flex items-center gap-24 xs:gap-24 sm:gap-28">
            <div className=" sm:w-[45%] w-[40%] h-28 xs:h-28 sm:h-32 md:h-32 rounded-lg bg-[#33445B] border-2 border-white text-white flex justify-center items-center text-[10px] md:text-lg lg:text-xl font-medium text-center">
              <p className=" w-[80%] text-lg sm:text-xl">Starting the Program </p>
            </div>

          

            <div className=" sm:w-[45%] w-[42%] h-28 xs:h-28 sm:h-32 md:h-32 rounded-lg bg-[#33445B] border-2 border-white text-white flex justify-center items-center text-[10px] md:text-lg lg:text-xl font-medium text-center">
              <p className=" w-[80%] text-lg sm:text-xl">
              Landing your job 
              </p>
            </div>
          </div>
          <div className=" w-[70%] flex justify-between">
            <FaPlay className=" text-2xl rotate-90" />
            <FaPlay className=" text-2xl -rotate-90" />
          </div>
          <div className=" w-full flex items-center gap-7 xs:gap-9 sm:gap-11">
            <div className=" sm:w-[45%] w-[40%] h-28 xs:h-28 sm:h-32 md:h-32 rounded-lg bg-[#33445B] border-2 border-white text-white flex justify-center items-center text-[10px] md:text-lg lg:text-xl font-medium text-center">
              <p className=" w-[80%] text-lg sm:text-xl"> 3 Months completely Offline course </p>
            </div>
            <FaPlay className=" text-2xl " />
            
            <div className=" sm:w-[45%] w-[40%] h-28 xs:h-28 sm:h-32 md:h-32 rounded-lg bg-[#33445B] border-2 border-white text-white flex justify-center items-center text-[10px] md:text-lg lg:text-xl font-medium text-center">
              <p className=" w-[80%] text-lg sm:text-xl">3 Months Placement Assistance </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
