import React from 'react'
import { Outlet } from 'react-router-dom'
import { Navbar } from './common/Navbar'
import { Footer } from './common/Footer'
export const Main = () => {
  return (
  
    <div className=' min-[320px]:w-full'>
    <Navbar/>
    <Outlet/>
    <Footer/>
    </div>
  )
}
