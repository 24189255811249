import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaPlay } from "react-icons/fa6";
import { IoPersonCircleOutline } from "react-icons/io5";
import { MdOutlinePhoneEnabled } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";
import { IconField } from "primereact/iconfield";
import { IoIosArrowForward } from "react-icons/io";
import { Button } from "primereact/button";
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { PiGraduationCapBold } from "react-icons/pi";
import { IoBookOutline } from "react-icons/io5";
import { Dialog } from "primereact/dialog";
import { MdOutlineArrowForward } from "react-icons/md";
import { IoCloseOutline } from "react-icons/io5";
import axios from "axios";
import { toast } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";
export const Faqpart = () => {
  const [visible, setVisible] = useState(false);
  const navigate = useNavigate();
  const initialstate = {
    Email: "",
    Phone: "",
    Name: "",
    Education: "",
    YOP: "",
    NotificationPermission: false,
  };

  const [detail, setdetail] = useState(initialstate);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setdetail({ ...detail, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(detail);

    await axios
      .post("http://localhost:4000/enroll", detail)
      .then((res) => {
        if (res.data) {
          toast.success("Enrolled successfully");
          setVisible(false);
        }
      })
      .catch((err) => console.log("not enrolled"))
      .finally(() => {
        // setShowModal1(false)
      });
  };

  return (
    <div className=" w-full  bg-[#CA85EA] flex flex-col items-center pt-10 sm:py-20  gap-12 4xl:py-32 4xl:gap-16 5xl:py-40 5xl:gap-24 6xl:py-48 6xl:gap-32 7xl:py-56 7xl:gap-52">
      <h1 className=" w-[84%] text-3xl 3xl:text-4xl 4xl:text-6xl 5xl:text-7xl 6xl:text-8xl 7xl:text-9xl text-center font-bold">
        FAQs
      </h1>
      <div className="w-[90%] md:w-[84%] flex flex-col justify-center items-center  ">
        <div
          className="group flex flex-col  rounded-tl-[40px] bg-white p-5 text-black w-full  "
          tabindex="1"
        >
          <div className="flex cursor-pointer items-center gap-6 justify-between p-3 pt-3 4xl:p-8 5xl:p-12 6xl:p-16 7xl:p-28">
            <span className=" font-bold  lg:text-xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl w-[80%] sm:w-[70%]">
              What features are included in your website plans?{" "}
            </span>
            <IoIosArrowForward className="text-2xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl group-focus:rotate-90" />
          </div>
          <div className=" lg:text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl 7xl:text-6xl  pl-3 leading-relaxed 4xl:pl-8 5xl:pl-12 6xl:pl-16 7xl:pl-24 invisible h-auto max-h-0 items-center opacity-0 duration-700 group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-[1000ms]">
            Our website plans include a variety of features such as customizable
            templates, domain registration, hosting services, e-commerce
            capabilities, SEO tools, analytics, and customer support. The
            specific features vary depending on the plan you choose.
          </div>
        </div>

        <div
          className="group flex flex-col gap-2  bg-white p-5 text-black w-full  "
          tabindex="2"
        >
          <div className="flex cursor-pointer items-center gap-6 justify-between p-3 4xl:p-8 5xl:p-12 6xl:p-16 7xl:p-28">
            <span className=" font-bold lg:text-xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl w-[80%] sm:w-[70%]">
              How do I get started with purchasing a website plan?
            </span>
            <IoIosArrowForward className="text-2xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl group-focus:rotate-90" />
          </div>
          <div className=" lg:text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl 7xl:text-6xl pl-3 leading-relaxed 4xl:pl-8 5xl:pl-12 6xl:pl-16 7xl:pl-24 invisible h-auto max-h-0 items-center opacity-0 duration-700 group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-[1000ms]">
            Getting started is easy! Simply explore our website plans on our
            website, select the plan that best fits your needs, and follow the
            prompts to complete your purchase securely. If you have any
            questions or need assistance, our customer support team is here to
            help.
          </div>
        </div>

        <div
          className="group flex flex-col gap-2  bg-white p-5 text-black w-full  "
          tabindex="3"
        >
          <div className="flex cursor-pointer items-center gap-6 justify-between p-3 4xl:p-8 5xl:p-12 6xl:p-16 7xl:p-28">
            <span className=" font-bold lg:text-xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl w-[80%] sm:w-[70%] ">
              What services are included in a typical SMM package?
            </span>
            <IoIosArrowForward className="text-2xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl group-focus:rotate-90" />
          </div>
          <div className=" lg:text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl 7xl:text-6xl pl-3 leading-relaxed 4xl:pl-8 5xl:pl-12 6xl:pl-16 7xl:pl-24 invisible h-auto max-h-0 items-center opacity-0 duration-700 group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-[1000ms]">
            A typical SMM package may include services such as social media
            account setup and optimization, content creation and posting,
            community management, social media advertising, analytics and
            reporting, and strategy development.
          </div>
        </div>

        <div
          className="group flex flex-col gap-2 bg-white p-5 text-black w-full "
          tabindex="4"
        >
          <div className="flex cursor-pointer items-center gap-10 justify-between p-3 4xl:p-8 5xl:p-12 6xl:p-16 7xl:p-28">
            <span className=" font-bold lg:text-xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl w-[80%] sm:w-[70%]">
              How do I determine which social media platforms are right for my
              business?
            </span>
            <IoIosArrowForward className="text-2xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl group-focus:rotate-90" />
          </div>
          <div className=" lg:text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl 7xl:text-6xl pl-3 leading-relaxed 4xl:pl-8 5xl:pl-12 6xl:pl-16 7xl:pl-24 invisible h-auto max-h-0 items-center opacity-0 duration-700 group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-[1000ms]">
            The choice of social media platforms depends on your target audience
            and business goals. Conduct research to identify where your target
            audience spends their time online and which platforms are most
            relevant to your industry.
          </div>
        </div>

        <div
          className="group flex flex-col gap-2 rounded-br-[40px] bg-white p-5 text-black w-full "
          tabindex="5"
        >
          <div className="flex cursor-pointer items-center gap-6 justify-between p-3 pb-3 4xl:p-8 5xl:p-12 6xl:p-16 7xl:p-28 4xl:pb-6 5xl:pb-9 7xl:pb-24">
            <span className=" font-bold lg:text-xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl w-[80%] sm:w-[70%] ">
              How long does it take to see results from social media marketing?
            </span>
            <IoIosArrowForward className=" text-2xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl group-focus:rotate-90" />
          </div>
          <div className=" lg:text-xl 4xl:text-3xl 5xl:text-4xl 6xl:text-5xl 7xl:text-6xl pl-3 leading-relaxed 4xl:pl-8 5xl:pl-12 6xl:pl-16 7xl:pl-24 invisible h-auto max-h-0 items-center opacity-0 duration-700 group-focus:visible group-focus:max-h-screen group-focus:opacity-100 group-focus:duration-[1000ms]">
            The timeline for seeing results from social media marketing can vary
            depending on factors such as the quality of your content, frequency
            of posting, engagement with your audience, and the effectiveness of
            your advertising campaigns.
          </div>
        </div>
      </div>
      <div className=" flex 7xl:h-72 xl:h-[68px] 3xl:h-24 4xl:h-36 5xl:h-44 6xl:h-60">
        <button
          onClick={() => setVisible(true)}
          className=" 7xl:w-[670px] 7xl:h-64 6xl:w-[480px] 6xl:h-48 5xl:w-96 5xl:h-36 4xl:w-80 4xl:h-28 3xl:w-64 3xl:h-20 2xl:w-52 xl:h-16 xl:w-40 2xl:h-[60px]  lg:w-36 lg:h-14 md:w-36 md:h-14 sm:w-32 sm:h-12 sm:text-base xs:w-28 xs:h-10 xs:text-sm  w-24 h-8 6xl:gap-7 7xl:gap-12  rounded-lg 5xl:rounded-xl 7xl:rounded-3xl items-center justify-center   md:gap-3 sm:gap-3 xs:gap-2 gap-1 md:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl 4xl:text-[37px] 5xl:text-5xl 6xl:text-7xl 7xl:text-8xl text-xs pb-1  5xl:gap-5   hover:bg-[#f79e1a] transition-all duration-500  bg-[#FFB039] flex font-bold  "
        >
          Enroll Now
          <FaPlay className="md:text-base  xs:mt-[2px] sm:mt-1 lg:mt-1 xl:text-lg xl:mt-1 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 4xl:mt-3 5xl:text-4xl 5xl:mt-3 6xl:text-6xl 6xl:mt-4 7xl:text-7xl 7xl:mt-7 text-[10px] " />
        </button>
      </div>
      <div className="card w-full flex justify-content-center">
        <Dialog
          className=" w-[80%] sm:w-[70%] md:w-[62%] lg:w-[48%] xl:w-[40%] 2xl:w-[38%] 4xl:w-[45%] 5xl:w-[42%] 6xl:w-[45%] 7xl:w-[45%]"
          visible={visible}
          modal
          onHide={() => {
            if (!visible) return;
            setVisible(false);
          }}
          content={({ hide }) => (
            <form
              className=" w-[100%] flex flex-col px-5 py-10 4xl:py-16 5xl:py-24 6xl:py-28 7xl:py-32 shadow-xl shadow-gray-700 drop gap-3 4xl:gap-6 5xl:gap-8 6xl:gap-14 7xl:gap-16   items-center rounded-xl 5xl:rounded-3xl"
              onSubmit={handleSubmit}
              style={{ backgroundColor: "#202E41" }}
            >
              <div className=" w-[90%] flex justify-between pb-3 4xl:pb-4 5xl:pb-10 6xl:pb-20 7xl:pb-24 ">
                <h1 className=" text-white text-xl md:text-2xl 2xl:text-3xl 3xl:text-4xl 4xl:text-6xl 5xl:text-7xl 6xl:text-9xl 7xl:text-[136px] font-semibold">
                  Enroll Here
                </h1>
                <IoCloseOutline
                  className=" text-3xl 3xl:text-4xl 4xl:text-5xl 5xl:text-6xl 6xl:text-8xl 7xl:text-9xl text-white cursor-pointer"
                  onClick={(e) => hide(e)}
                />
              </div>

              <IconField
                iconPosition="right"
                className=" placeholder:pb-1 bg-[#27384f]  w-[90%] h-12 xs:h-14  sm:h-16 md:h-16 lg:h-[66px] xl:h-[66px] 3xl:h-20  3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16 rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
              >
                <InputIcon className="7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                  <IoPersonCircleOutline />
                </InputIcon>
                <InputText
                  name="Name"
                  value={detail.Name}
                  onChange={handleChange}
                  type="text"
                  v-model="value1"
                  placeholder="Name"
                  className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                />
              </IconField>
              <IconField
                iconPosition="right"
                className=" placeholder:pb-1 bg-[#27384f]  w-[90%] h-12 xs:h-14  sm:h-16 md:h-16 lg:h-[66px] xl:h-[66px] 3xl:h-20 3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16  rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
              >
                <InputIcon className="7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                  <MdOutlineEmail />
                </InputIcon>
                <InputText
                  name="Email"
                  value={detail.Email}
                  onChange={handleChange}
                  type="email"
                  v-model="value1"
                  placeholder="Email"
                  className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                />
              </IconField>

              <IconField
                iconPosition="right"
                className=" placeholder:pb-1 bg-[#27384f]  w-[90%] h-12 xs:h-14  sm:h-16 md:h-16 lg:h-[66px] xl:h-[66px] 3xl:h-20 3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16 rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
              >
                <InputIcon className="7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                  <IoBookOutline />
                </InputIcon>
                <InputText
                  name="Education"
                  value={detail.Education}
                  onChange={handleChange}
                  type="text"
                  v-model="value1"
                  placeholder="Education Qualification"
                  className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                />
              </IconField>
              <div className=" flex flex-col md:flex md:flex-row items-center w-[90%] gap-4 7xl:gap-9">
                <IconField
                  iconPosition="right"
                  className=" placeholder:pb-1 bg-[#27384f] w-full  2xl:w-[90%] h-12 xs:h-14  sm:h-16 md:h-16 lg:h-[66px]  xl:h-[66px] 3xl:h-20 3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16 rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
                >
                  <InputIcon className="7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                    <MdOutlinePhoneEnabled />
                  </InputIcon>
                  <InputText
                    name="Phone"
                    value={detail.Phone}
                    onChange={handleChange}
                    type="text"
                    v-model="value1"
                    placeholder="Phone No"
                    className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                  />
                </IconField>
                <IconField
                  iconPosition="right"
                  className=" placeholder:pb-1 bg-[#27384f]  w-full  2xl:w-[90%] h-12 xs:h-14  sm:h-16 lg:h-[66px]  xl:h-[66px] 3xl:h-20 3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16  rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
                >
                  <InputIcon className=" 7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                    <PiGraduationCapBold />
                  </InputIcon>
                  <InputText
                    name="YOP"
                    value={detail.YOP}
                    onChange={handleChange}
                    type="text"
                    v-model="value1"
                    placeholder="Graduation Year"
                    className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                  />
                </IconField>
              </div>

              <div className="flex align-items-center gap-2">
                <Button
                  type="submit"
                  text
                  className=" text-black  bg-[#FFB039] 2xl:w-48 2xl:h-14 3xl:w-64 3xl:h-20 4xl:w-[330px] 4xl:h-24 5xl:w-[400px] 5xl:h-32 6xl:w-[500px] 6xl:h-44  7xl:w-[600px] 7xl:h-52 xl:w-40 xl:h-14 lg:w-40 lg:h-14 md:w-36 md:h-12 sm:w-36 sm:h-12 xs:w-36 xs:h-12 w-36 h-12 md:text-xl  text-lg font-bold 2xl:text-2xl 3xl:text-4xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl 7xl:text-8xl 7xl:font-bold 4xl:mt-4 5xl:mt-10 6xl:mt-20 flex justify-around items-center   2xl:font-semibold rounded-xl 6xl:rounded-2xl 7xl:rounded-3xl mt-3 hover:shadow-xl hover:shadow-[#3d3d4b] transform duration-500 hover:scale-90 "
                >
                  
                  Enroll Now
                  <MdOutlineArrowForward className=" 7xl:mt-3" />
                </Button>
              </div>
            </form>
          )}
        ></Dialog>
      </div>
    </div>
  );
};
