import React, { useState } from "react";
import "./multicloud.css";
import "primereact/resources/themes/saga-blue/theme.css"; // Theme CSS
import "primereact/resources/primereact.min.css"; // PrimeReact CSS
import "primeicons/primeicons.css"; // PrimeIcons CSS
import { FaPlay } from "react-icons/fa";
import { Aboutprogram } from "../common/aboutprogram";
import { Testimonial } from "../common/testimonial";
import { Jobguarantee } from "../common/jobguarantee";
import { Project } from "../common/project";
import { Whoistfor } from "../common/whoistfor";
import { Faqpart } from "../common/Faq";
import "../font/Sentic Text.otf";
import { IoCloseOutline } from "react-icons/io5";
import axios from "axios";
import { toast } from "react-toastify";
import {  useNavigate } from "react-router-dom";
import { IoPersonCircleOutline } from "react-icons/io5";
import { MdOutlinePhone } from "react-icons/md";
import { MdOutlineEmail } from "react-icons/md";
import { IconField } from "primereact/iconfield";
import { IoIosArrowForward } from "react-icons/io";
import { Button } from 'primereact/button';
import { InputIcon } from "primereact/inputicon";
import { InputText } from "primereact/inputtext";
import { PiGraduationCapBold } from "react-icons/pi";
import { IoBookOutline } from "react-icons/io5";
import { Dialog } from 'primereact/dialog';
import { MdOutlineArrowForward } from "react-icons/md";
export const Multicloud = () => {
  const [visible, setVisible] = useState(false);
  // const [showModal, setShowModal] = useState(false);
  // const [showModal1, setShowModal1] = useState(false);
  const navigate = useNavigate();
  const initialstate = {
   
    Email: "",
    Phone: "",
    Name: "",
    Education: "",
    YOP: "",
    NotificationPermission: false,
  };
  
  const [detail, setdetail] = useState(initialstate);
 
  const handleChange = (event) => {
    const { name, value } = event.target;
    setdetail({ ...detail, [name]: value });
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(detail);
   
    await axios
      .post("http://localhost:4000/enroll",detail)
      .then((res) => {
        if (res.data) {
          toast.success("Enrolled successfully");
          setVisible(false)
        }
      })
      .catch((err) => console.log("not enrolled"))
      .finally(()=>{
        
      })
      
  };
 

  return (
    <div className=" w-[100%] h-fit font-[light]">
      <div className=" w-full bg-[#0B192C] flex flex-col items-center py-10  lg:py-20 5xl:py-28 6xl:py-36 7xl:py-48 gap-5 xs:gap-10 xl:gap-14 4xl:gap-16 5xl:gap-28 6xl:gap-32 7xl:gap-36">
      <div className="w-[90%] lg:w-[82%] flex justify-start ">
      <ul className=" flex p-2 md:gap-2 lg:gap-3 xl:gap-3 5xl:gap-6 text-xs sm:text-sm md:text-lg lg:text-xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl text-white items-center">
        <li className=" cursor-pointer  5xl:p-4 ">
          Home
        </li>
        <IoIosArrowForward/>
        <li className=" cursor-pointer  5xl:p-4  transition-all duration-300">
        Programs
        </li>
        <IoIosArrowForward/>
        <li className=" cursor-pointer  5xl:p-4 transition-all duration-300">
        Multi-Cloud & DevOps Mentorship Program
        </li>
      </ul>
    </div>
        <div className=" flex w-full  ">
          <div className=" w-[60%] flex flex-col items-end gap-6 lg:gap-12 3xl:gap-16 5xl:gap-24 6xl:gap-32 ">
            <h1 className=" text-2xl sm:text-3xl md:text-4xl lg:text-6xl 4xl:text-7xl 5xl:text-9xl 6xl:text-[140px] 7xl:text-[170px]   text-white font-extrabold w-[90%] lg:w-[84%]">
              Multi-Cloud & DevOps Mentorship Program
            </h1>
            <div className="w-[90%] lg:w-[84%] pt-1  lg:pt-3">
              <p className=" text-white w-[70%] lg:w-[60%] text-xs sm:text-sm md:text-lg lg:text-xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl  ">
                Master Multi-Cloud & DevOps with MentorFox. Gain hands-on
                experience with industrial experts. Secure guaranteed placements
                in top companies.
              </p>
            </div>
            <div className="hidden w-[90%] lg:w-[84%] xs:flex flex-col lg:gap-7 gap-4 5xl:gap-14 6xl:gap-20 ">
              <div className=" w-full flex md:gap-5 lg:gap-7 gap-4 5xl:gap-14 6xl:gap-20 7xl:gap-24  ">
                <div className=" w-[40%] xs:w-[34%] md:w-[37%] xl:w-[32%] lg:w-[37%] 4xl:w-[40%] 4xl:h-60 5xl:w-[40%] 5xl:h-80 6xl:w-[40%] 6xl:h-[400px] 7xl:w-[40%] 7xl:h-[450px] h-[68px] md:h-36 3xl:h-44 4xl:gap-3 6xl:gap-6 bg-[#00CB91] rounded-xl 5xl:rounded-2xl 7xl:rounded-3xl flex flex-col justify-center items-center ">
                  <h1 className="text-xs md:text-2xl 3xl:text-3xl 4xl:text-5xl 5xl:text-7xl 6xl:text-8xl 7xl:text-9xl  font-semibold  w-[70%] md:w-[70%] lg:w-[70%]">
                   
                    3 Months
                  </h1>
                  <p className=" text-xs md:text-lg 3xl:text-xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl 6xl:-ml-24  w-[70%] md:w-[70%] lg:w-[70%]  4xl:w-[60%] 4xl:-ml-14">
                    Completely Offline course
                  </p>
                </div>
                <div className=" w-[40%] xs:w-[34%] md:w-[37%] xl:w-[32%] lg:w-[37%] 4xl:w-[40%] 4xl:h-60 5xl:w-[40%] 5xl:h-80 6xl:w-[40%] 6xl:h-[400px] 7xl:w-[40%] 7xl:h-[450px] h-[68px] md:h-36 3xl:h-44 4xl:gap-3 6xl:gap-6 bg-[#CA86EA] rounded-xl 5xl:rounded-2xl 7xl:rounded-3xl flex flex-col justify-center items-center">
                  <h1 className=" text-xs md:text-2xl 3xl:text-3xl 4xl:text-5xl 5xl:text-7xl 6xl:text-8xl 7xl:text-9xl   font-semibold  w-[70%] md:w-[70%] lg:w-[70%]">
                   
                    Guaranteed
                  </h1>
                  <p className=" text-xs md:text-lg 3xl:text-xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl   w-[70%] md:w-[70%] lg:w-[70%] ">
                    Placement in top companies
                  </p>
                </div>
              </div>
              <div className=" w-full flex md:gap-5 lg:gap-7 gap-4 5xl:gap-14 6xl:gap-20">
                <div className=" w-[40%] xs:w-[34%] md:w-[37%] xl:w-[32%] lg:w-[37%] 4xl:w-[40%] 4xl:h-60 h-[68px] 5xl:w-[40%] 5xl:h-80 6xl:w-[40%] 6xl:h-[400px] 7xl:w-[40%] 7xl:h-[450px] md:h-36 3xl:h-44 4xl:gap-3 6xl:gap-6 bg-[#FF4949] rounded-xl 5xl:rounded-2xl 7xl:rounded-3xl flex flex-col justify-center items-center">
                  <h1 className=" text-xs md:text-2xl 3xl:text-3xl 4xl:text-5xl 5xl:text-7xl 6xl:text-8xl 7xl:text-9xl   font-semibold  w-[70%] md:w-[80%] lg:w-[70%]">
                    Real World Projects
                  </h1>
                  <p className=" text-xs md:text-lg 3xl:text-xl 4xl:text-3xl  5xl:text-5xl 6xl:text-6xl 7xl:text-7xl   w-[70%] md:w-[80%] lg:w-[70%]">
                    With Guidance
                  </p>
                </div>
                <div className=" w-[40%] xs:w-[34%] md:w-[37%] xl:w-[32%] lg:w-[37%] 4xl:w-[40%] 4xl:h-60 5xl:w-[40%]  5xl:h-80 6xl:w-[40%] 6xl:h-[400px] 7xl:w-[40%] 7xl:h-[450px] h-[68px] md:h-36 3xl:h-44 4xl:gap-3 6xl:gap-6 bg-[#FFC339] rounded-xl 5xl:rounded-2xl 7xl:rounded-3xl  flex flex-col justify-center items-center">
                  <h1 className=" text-xs md:text-2xl 3xl:text-3xl 4xl:text-5xl 5xl:text-7xl 6xl:text-8xl 7xl:text-9xl   font-semibold  w-[70%] md:w-[70%] lg:w-[70%]">
                    Real-time Internships
                  </h1>
                  <p className=" text-xs md:text-lg 3xl:text-xl 4xl:text-3xl 5xl:text-5xl 6xl:text-6xl 7xl:text-7xl  w-[70%] md:w-[70%] lg:w-[70%]">
                    From day one
                  </p>
                </div>
              </div>
            </div>
            <div className="hidden md:w-[90%]  lg:w-[84%] md:flex   ">
              <button
                // onClick={() => setShowModal(true)}
                onClick={() => setVisible(true)}
                className=" text-white flex border-2 border-white  hover:shadow-lg hover:shadow-gray-500 hover:transition-all hover:duration-500 hover:ease-in-out 7xl:w-[670px] 7xl:h-64 6xl:w-[480px] 6xl:h-48 5xl:w-96 5xl:h-36 4xl:w-80 4xl:h-28 3xl:w-64 3xl:h-20 2xl:w-52 xl:h-16 xl:w-40 2xl:h-[60px]  lg:w-36 lg:h-14 md:w-36 md:h-14  sm:w-32 sm:h-12 sm:text-base xs:w-28 xs:h-10 xs:text-sm  w-24 h-8 6xl:gap-7 7xl:gap-12  rounded-lg 5xl:rounded-xl 7xl:rounded-3xl items-center justify-center md:gap-3 font-semibold md:text-lg xl:text-xl 2xl:text-2xl 3xl:text-3xl 4xl:text-[37px] 5xl:text-5xl 6xl:text-7xl 7xl:text-8xl text-xs pb-1  5xl:gap-5"
              >
              Enroll Now <FaPlay className="md:text-base mt-1 xs:mt-[2px] sm:mt-1 lg:mt-1 xl:text-lg xl:mt-1 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 4xl:mt-3 5xl:text-4xl 5xl:mt-3 6xl:text-6xl 6xl:mt-4 7xl:text-7xl 7xl:mt-7 text-[10px] " />
              </button>
            </div>
          </div>
          <div className=" w-[40%] h-[60%] ">
            <img
              src="/assets/Group 59578.png"
              alt=""
              className=" lg:w-[90%] lg:h-[95%] xl:w-[80%] xl:h-[90%] 2xl:w-[90%] xl:-ml-32 -ml-8 xs:-ml-10 xs:mt-10"
            />
          </div>
        </div>
        <div className="card w-full flex justify-content-center">
            <Dialog
            className=" w-[80%] sm:w-[70%] md:w-[62%] lg:w-[48%] xl:w-[40%] 2xl:w-[38%] 4xl:w-[45%] 5xl:w-[42%] 6xl:w-[45%] 7xl:w-[45%]"
                visible={visible}
                modal
                onHide={() => {if (!visible) return; setVisible(false); }}
                content={({ hide }) => (
                    <form className=" w-[100%] flex flex-col px-5 py-10 4xl:py-16 5xl:py-24 6xl:py-28 7xl:py-32 shadow-xl shadow-gray-700 drop gap-3 4xl:gap-6 5xl:gap-8 6xl:gap-14 7xl:gap-16   items-center rounded-xl 5xl:rounded-3xl" onSubmit={handleSubmit} style={{ backgroundColor:"#202E41"  }}>
                      <div className=" w-[90%] flex justify-between pb-3 4xl:pb-4 5xl:pb-10 6xl:pb-20 7xl:pb-24 ">
                      <h1 className=" text-white text-xl md:text-2xl 2xl:text-3xl 3xl:text-4xl 4xl:text-6xl 5xl:text-7xl 6xl:text-9xl 7xl:text-[136px] font-semibold">Enroll Here</h1>
                      <IoCloseOutline className=" text-3xl 3xl:text-4xl 4xl:text-5xl 5xl:text-6xl 6xl:text-8xl 7xl:text-9xl text-white cursor-pointer" onClick={(e) => hide(e)} />
                      </div>
                      
                         <IconField
                            
                            iconPosition="right"
                            className=" placeholder:pb-1 bg-[#27384f]  w-[90%] h-12 xs:h-14  sm:h-16 md:h-16 lg:h-[66px] xl:h-[66px] 3xl:h-20  3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16 rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
                          >
                            <InputIcon className="7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                              <IoPersonCircleOutline />
                            </InputIcon>
                            <InputText
                            name="Name"
                            value={detail.Name}
                            onChange={handleChange}
                            type="text"
                              v-model="value1"
                              placeholder="Name"
                              className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                            />
                          </IconField>
                         <IconField
                           
                            iconPosition="right"
                            className=" placeholder:pb-1 bg-[#27384f]  w-[90%] h-12 xs:h-14  sm:h-16 md:h-16 lg:h-[66px] xl:h-[66px] 3xl:h-20 3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16  rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
                          >
                            <InputIcon className="7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                              <MdOutlineEmail />
                            </InputIcon>
                            <InputText
                             name="Email"
                             value={detail.Email}
                             onChange={handleChange}
                             type="email"
                              v-model="value1"
                              placeholder="Email"
                              className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                            />
                          </IconField>
                          
                          <IconField
                           
                            iconPosition="right"
                            className=" placeholder:pb-1 bg-[#27384f]  w-[90%] h-12 xs:h-14  sm:h-16 md:h-16 lg:h-[66px] xl:h-[66px] 3xl:h-20 3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16 rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
                          >
                            <InputIcon className="7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                              < IoBookOutline />
                            </InputIcon>
                            <InputText
                             name="Education"
                             value={detail.Education}
                             onChange={handleChange}
                             type="text"
                              v-model="value1"
                              placeholder="Education Qualification"
                              className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                            />
                          </IconField>
                          <div className=" flex flex-col md:flex md:flex-row items-center w-[90%] gap-4 7xl:gap-9">
                          <IconField
                           
                            iconPosition="right"
                            className=" placeholder:pb-1 bg-[#27384f] w-full  2xl:w-[90%] h-12 xs:h-14  sm:h-16 md:h-16 lg:h-[66px]  xl:h-[66px] 3xl:h-20 3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16 rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
                          >
                            <InputIcon className="7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                              < MdOutlinePhone/>
                            </InputIcon>
                            <InputText
                             name="Phone"
                             value={detail.Phone}
                             onChange={handleChange}
                             type="text"
                              v-model="value1"
                              placeholder="Phone No"
                              className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                            />
                          </IconField>
                          <IconField
                           
                          
                            iconPosition="right"
                            className=" placeholder:pb-1 bg-[#27384f]  w-full  2xl:w-[90%] h-12 xs:h-14  sm:h-16 lg:h-[66px]  xl:h-[66px] 3xl:h-20 3xl:text-2xl 4xl:h-28  4xl:text-4xl 5xl:h-36  5xl:text-5xl 6xl:h-52  6xl:text-7xl 6xl:pl-10 7xl:h-60  7xl:text-8xl 7xl:pl-16  rounded-2xl 6xl:rounded-3xl pl-3 sm:pl-5 xl:pl-8 flex items-center text-white text-base xs:text-lg xl:text-lg placeholder:text-white"
                          >
                            <InputIcon className=" 7xl:-mt-10 text-white pr-6 text-2xl 3xl:text-3xl 4xl:text-4xl 5xl:text-5xl 6xl:text-6xl 7xl:text-8xl">
                              <PiGraduationCapBold />
                            </InputIcon>
                            <InputText
                             name="YOP"
                             value={detail.YOP}
                             onChange={handleChange}
                               type="text"
                              v-model="value1"
                              placeholder="Graduation Year"
                              className="bg-[#27384f] text-white focus:border-0 placeholder:text-white"
                            />
                          </IconField>

                          </div> 
                          
                        <div className="flex align-items-center gap-2">
                            <Button  type="submit"  text className=" text-black  bg-[#FFB039] 2xl:w-48 2xl:h-14 3xl:w-64 3xl:h-20 4xl:w-[330px] 4xl:h-24 5xl:w-[400px] 5xl:h-32 6xl:w-[500px] 6xl:h-44  7xl:w-[600px] 7xl:h-52 xl:w-40 xl:h-14 lg:w-40 lg:h-14 md:w-36 md:h-12 sm:w-36 sm:h-12 xs:w-36 xs:h-12 w-36 h-12 md:text-xl  text-lg font-bold 2xl:text-2xl 3xl:text-4xl 4xl:text-5xl 5xl:text-6xl 6xl:text-7xl 7xl:text-8xl 7xl:font-bold 4xl:mt-4 5xl:mt-10 6xl:mt-20 flex justify-around items-center   2xl:font-semibold rounded-xl 6xl:rounded-2xl 7xl:rounded-3xl mt-3 hover:shadow-xl hover:shadow-[#3d3d4b] transform duration-500 hover:scale-90 "> Enroll Now<MdOutlineArrowForward className=" 7xl:mt-3"/></Button>
                        </div>
                    </form>
                )}
            ></Dialog>
        </div>
     
        <div className=" xs:hidden w-[84%] lg:w-[84%] flex flex-col lg:gap-7 xs:gap-4 gap-9  ">
          <div className=" w-full flex md:gap-5 lg:gap-7 xs:gap-4 gap-9  ">
            <div className=" w-[45%] xs:w-[34%] md:w-[37%] xl:w-[32%] lg:w-[37%] h-24 md:h-36 3xl:h-44 bg-[#00CB91] rounded-xl flex flex-col justify-center items-center ">
              <h1 className="text-lg md:text-2xl 3xl:text-3xl font-semibold  w-[70%] md:w-[70%] lg:w-[60%]">
                3 Months
              </h1>
              <p className=" text-sm md:text-lg 3xl:text-xl  w-[70%] md:w-[70%] lg:w-[60%]">
                Completely Offline course
              </p>
            </div>
            <div className=" w-[45%] xs:w-[34%] md:w-[37%] xl:w-[32%] lg:w-[37%] h-24 md:h-36 3xl:h-44 bg-[#CA86EA] rounded-xl flex flex-col justify-center items-center">
              <h1 className=" text-lg md:text-2xl 3xl:text-3xl font-semibold  w-[70%] md:w-[70%] lg:w-[60%]">
                Guaranteed
              </h1>
              <p className=" text-sm md:text-lg 3xl:text-xl  w-[70%] md:w-[70%] lg:w-[60%] ">
                Placement in top companies
              </p>
            </div>
          </div>
          <div className=" w-full flex md:gap-5 lg:gap-7 xs:gap-4 gap-9">
            <div className=" w-[45%] xs:w-[34%] md:w-[37%] xl:w-[32%] lg:w-[37%] h-24 md:h-36 3xl:h-44 bg-[#FF4949] rounded-xl flex flex-col justify-center items-center">
              <h1 className=" text-lg md:text-2xl 3xl:text-3xl font-semibold  w-[70%] md:w-[80%] lg:w-[70%]">
                Real World Projects
              </h1>
              <p className=" text-sm md:text-lg 3xl:text-xl  w-[70%] md:w-[80%] lg:w-[70%]">
                With Guidance
              </p>
            </div>
            <div className=" w-[45%] xs:w-[34%] md:w-[37%] xl:w-[32%] lg:w-[37%] h-24 md:h-36 3xl:h-44 bg-[#FFC339] rounded-xl flex flex-col justify-center items-center">
              <h1 className=" text-lg md:text-2xl 3xl:text-3xl font-semibold  w-[70%] md:w-[70%] lg:w-[60%]">
                Real-time Internships
              </h1>
              <p className=" text-sm md:text-lg 3xl:text-xl md:pl-2 w-[70%] md:w-[70%] lg:w-[60%]">
                From day one
              </p>
            </div>
          </div>
        </div>
        <div className=" w-[80%]  xs:-mt-10 sm:-mt-8 flex md:justify-start justify-center md:hidden ">
          
         
            <button className=" text-white flex border-2 border-white md:w-32 md:h-12 sm:w-32 sm:h-12 sm:text-base xs:w-28 xs:h-10 xs:text-sm  w-24 h-8 hover:bg-white hover:text-black hover:transition-all hover:duration-500 rounded-lg items-center justify-center md:gap-3 font-medium md:text-xl text-xs gap-2 pb-1 ">
            Enroll Now <FaPlay className="md:text-base  xs:mt-[2px] sm:mt-1 lg:mt-1 xl:text-lg xl:mt-1 2xl:text-xl 3xl:text-2xl 4xl:text-3xl 4xl:mt-3 5xl:text-4xl 5xl:mt-3 6xl:text-6xl 6xl:mt-4 7xl:text-7xl 7xl:mt-7 text-[10px] " />
            </button>
          
        </div>
      </div>
      <Aboutprogram />
      <Testimonial />
      <Jobguarantee />
      <Project />
      <Whoistfor />
      <Faqpart />
    </div>
  );
};
